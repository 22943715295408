import { useState } from "react";
import { useForm, Controller} from "react-hook-form";
import {
  Link,
} from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import logo from "../../../../assets/images/logo-text-w.svg"
import load from "../../../../assets/images/mt-load-w.png"
import ButtonSubmit from "../../../../components/atoms/Button/index";
import CircleIcon from "../../../../components/atoms/CircleIcon/index";
import CustomizedTooltip from "../../../../components/atoms/Tooltip/index";
import Spinner from "../../../../components/atoms/Progress/index";
import FormInput from "../../../../components/atoms/FormInput/index";
////////////////////////////////////////////////////////////////////////////////////////////////////

import { useAuthDispatch, useAuthState } from '../../context/authContext';

////////////////////////////////////////////////////////////////////////////////////////////////////
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
////////////////////////////////////////////////////////////////////////////////////////////////////


const schema = yup.object({
  email: yup.string().required('Este campo es obligatorio'),
  password: yup.string().required('Este campo es obligatorio'),
}).required();

const Login = () => {
    const authDispatch = useAuthDispatch();
    const  authState = useAuthState();
    const  { isAuthenticating } = useAuthState();

    const [isDisable, setDisable] = useState();
    const [rememberMe, setRemember] = useState(true);

    const { control, handleSubmit } = useForm({
      resolver: yupResolver(schema)
    });

    const onSubmit = handleSubmit(data => {
      setDisable(true);
      return authDispatch.login(data);
    })

    const onChangeRemember = (e) => {
      setRemember(e)
    }

    return (
      <div className="page">
      {isAuthenticating && <Spinner active={isAuthenticating} /> }
        <div className="div-container">
          <div className="div-one">
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
              <img className="img-mt" src={logo} alt="Logo" />
              <img className="img" src={load} alt="Logo" />
            </Box>
            <Box className="box-icons">
              <div className="mr-20 icon-box">
                <CircleIcon title="Asistencias" srcImg="checklist.svg"></CircleIcon>
              </div>
              <div className="mr-20 icon-box">
                <CircleIcon title="Actividades" type="big" srcImg="location.svg"></CircleIcon>
              </div>
              <div className="icon-box">
                <CircleIcon title="Localizaciones" srcImg="checklist.svg"></CircleIcon>
              </div>
            </Box>
            <Box sx={{ display: 'flex', width:'100%', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-around', flexDirection: 'row', marginTop: '80px' }}>
              <h1 className="description">Una plataforma rápida y efectiva</h1>
            </Box>

          </div>
          <div className="div-two">
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap',  alignItems: 'center', justifyContent: 'center', flexDirection: 'column'  }}>
           
              <form className="form-login" onSubmit={onSubmit}>
              <div className="welcome-message">
                <h1>¡Bienvenidos de vuelta!</h1>
                <p className="subtitle-heading subtitle">Accede a todos los datos de tu operación en tiempo real</p>
              </div>
              <Box mb={2} sx={{ display: 'flex', width: '100%', flexDirection: 'column', marginBottom: '22px'  }}>
                <InputLabel className="label">
                  Ingresa tu correo o ID
                </InputLabel>
                <FormInput 
                  title=""
                  control={control}
                  type="email"
                  name="email">
                </FormInput>
                
              </Box>
              <Box mb={2} sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                <InputLabel className="label">
                  Ingresa tu contraseña
                </InputLabel>
                <FormInput 
                  title=""
                  control={control}
                  type="password"
                  name="password">
                </FormInput>
              </Box>

              <Box mb={2} sx={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: '15px' }}>
     

         
                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <CustomizedTooltip width="240" type="tooltip-fixed" open={!rememberMe} label={rememberMe?
                 "" : 
                  'La sesión se cerrará después de unos minutos de inactividad'} >
                      <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <Controller
                          name="remember"
                          control={control}
                          defaultValue={rememberMe}
                          render={({ field: { onChange, value }, fieldState: { error } }) => <Checkbox  
                            defaultChecked={rememberMe} 
                            onChange={(event, value) => {
                            onChangeRemember(value);
                          }} sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, paddingLeft: 0 }} />}
                        />
                      </Box>
                    </CustomizedTooltip>
                    <p className="subtitle-heading">Mantener sesión activa</p>
                </Box>
           
                <Box mb={2} sx={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', marginBottom: '0' }}>
                  <Link className="hyperlink" to="/forgo-password">¿Olvidaste tu contraseña? </Link>
                </Box>
                 
              </Box>
              <Box mb={2} sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', marginTop: '90px' }}>
                <ButtonSubmit 
                  onClick={onSubmit} 
                  title="Ingresar" 
                  width="130" 
                  disabled={isDisable && !authState.error}
                >
                </ButtonSubmit>
              </Box>
              {
                authState.error 
                ? 
                (authState.error && authState.error) === 'account blocked' 
                ?
                <div className="error">Tenemos un problema con tu cuenta. Comunícate con tu administrador</div> 
                :
                <div className="error">Usuario o contraseña incorrectos</div> 
                : 
                null
              }
              </form>
            </Box>
          </Box>
        </div>
          </div>
        </div>
       
      
      
    );
}
export default Login;

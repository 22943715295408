import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userService } from "../../services/user.service";
import { userGroupsService } from "../../../UsersGroups/services/user-groups.service";
import Icon from "../../../../components/atoms/Icon";
import PropTypes from 'prop-types';
import DialogConfirm from "../../../../components/molecules/DialogConfirm/index";
import Chip from "../../../../components/atoms/Chip/index";
import CustomizedTooltip from "../../../../components/atoms/Tooltip/index"
////////////////////////////////////////////////////////////////////////////////////////////////////
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses }  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#C9C9C9',
    color: '#000',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function User(props) {

  const [userEdit, setUserEdit] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const [APIData, setAPIData] = useState([]);

  useEffect(() => {
   // console.log(props)
    const fetchUserChecks = async () =>{
      if(props?.idGroup){
        userGroupsService.getById(props.idGroup)
          .then((response) => {
            let users = response?.data?.users
            //setusersChecks(users)
            if(users.length > 0){
              //console.log("UserGroups",users)
              return setAPIData(APIData => APIData.map(data => {
                return  users.some(users  => users._id === data._id) 
                ? { ...data, selected: true } 
                : data
              }));
            }
          })
          .catch(() => console.log("error"));
      }
    }
    
    const fetchUsers = async () => {
      userService.getAll()
        .then((response) => {
          let users = response.data
          setAPIData(users);
  
          if(props?.idGroup){
            fetchUserChecks()
          }
        })
        .catch((e) => console.log("error!", e));
    }
    fetchUsers()
  }, [])


  useEffect(() => {
      checkIfAllselected()
  })

  const onEdit = (id) => {
    navigate('/users/edit/'+ id)
  }

  const handleChange = (event) => {
    setAPIData(APIData => APIData.map(data => data._id === event._id ? { ...data, selected: !data.selected } : data));
  };

  useEffect(() => {
    if(props && props.handleChangeCheck){
      props?.handleChangeCheck(APIData)
    }else{
      return;
    }
  }, [APIData])

  const handleCheckAll = () => {
    if(props && props?.idGroup !== ""){
      setAPIData(APIData => APIData.map(data => ({ ...data, selected : !allChecked }) ));
      setAllChecked(!allChecked);
      props.handleChangeCheck(APIData)
    }
    
  }

  const someCompleted = () =>{
    return APIData.filter(usr => usr.selected).length > 0 && !allChecked;
  }

  const checkIfAllselected = () => {
    if(APIData.filter(usr => usr.selected).length === APIData.length){
      setAllChecked(true)
    }else{
      setAllChecked(false)
    }
  }

  const onOpenConfirmation = (user) => {
    setUserEdit(user)
    setOpen(true);
  }

  const onStatusUpdate = () => {
    let newStatus = {};
    if(userEdit.status === 'blocked'){
      newStatus.status = 'active'
    }else{
      newStatus.status = 'blocked'
    }
    userService.blockUser(userEdit._id,newStatus)
    .then((response) => {
      setAPIData(APIData => APIData.map(data => data._id === response.data._id ? { ...response.data} : data));
      setOpen(false);
      setOpenSuccess(true)
    })
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const checkSelected =  (data) => {
    if(data?.selected === true){
      return true
    }else{
      return false
    }
  }
  return (
    <div>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
            <TableContainer className="table-custom" component={Paper}>
            <Table sx={{ minWidth: 300 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                {props.actions === false  && 
                    <StyledTableCell>
                      <Box mb={2} sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: '0' }}>
                        <Checkbox
                          checked={allChecked}
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, paddingLeft: 0 }}
                          indeterminate={someCompleted()}
                          onChange={handleCheckAll}
                        />
                      </Box>
                    </StyledTableCell>
                  }
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>{props.actions === true ? 'Nombres' : 'Usuario'}</StyledTableCell>
                  <StyledTableCell>Cargo</StyledTableCell>
                  <StyledTableCell>Correo</StyledTableCell>
                  {props.actions === true && <StyledTableCell>Rol</StyledTableCell> }
                  {props.actions === true && <StyledTableCell>Activo desde</StyledTableCell> }
                  {props.actions === true && <StyledTableCell>Estado</StyledTableCell> }
                  {props.actions === true && <StyledTableCell>Acciones</StyledTableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {APIData.map((data)  => (
                  
                  <StyledTableRow
                    key={data._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >

                    {props.actions === false &&
                      <StyledTableCell component="th" scope="data">
                        <Box mb={2} sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: '0' }}>
                          <FormControlLabel
                              label=""
                              control={
                              <Checkbox 
                              sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, paddingLeft: 0 }}
                              checked={checkSelected(data)}
                              onChange={() => handleChange(data)}
                              />}
                            />
                        </Box>
                      </StyledTableCell>
                    }
                    <StyledTableCell component="th" scope="data">
                      {data.id}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="data">
                      {data.names + ' ' + data.surnames}
                    </StyledTableCell>
                    {props.actions === true && 
                      <StyledTableCell component="th" scope="data">
                        {data.role}
                      </StyledTableCell>
                    }
                   
                    <StyledTableCell component="th" scope="data">
                      {data.email}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="data">
                      {data.position?.name || '-'}
                    </StyledTableCell>

                    {props.actions === true && 
                      <StyledTableCell component="th" scope="data">
                        {data.loggedFrom || '-'}
                      </StyledTableCell>
                    }
                    {props.actions === true && 
                      <StyledTableCell>
                        <Chip status={data.status}></Chip>
                      </StyledTableCell>
                    }
                    {props.actions === true && 
                    <StyledTableCell>
                      <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', }}>
                        <CustomizedTooltip label="Editar">
                          <IconButton onClick={() => onEdit(data._id)} aria-label="delete">
                              <Icon icon="mt-lapiz-box" color="black"></Icon>
                          </IconButton>
                        </CustomizedTooltip>
                        <CustomizedTooltip label={(data.status === 'active' || data.status === 'enabled') ? 'Inhabilitar' : 'Habilitar' }>
                          <IconButton onClick={() => onOpenConfirmation(data)} aria-label="delete">

                              {data.status === 'active' || data.status === 'pending' || data.status === 'enabled' 
                              ?  <Icon icon="mt-alert-two" color="pink"></Icon>
                              :
                                <Icon icon="mt-check" color="green"></Icon>
                              }
                          </IconButton>
                        </CustomizedTooltip>

                      </Box>
                    </StyledTableCell>
                    }
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
       
          <DialogConfirm
            title={userEdit.status === 'active'  || userEdit.status === 'enabled' ? 'Inhabilitar usuario' : 'Habilitar usuario'}
           open={open}
           onClick={onStatusUpdate}
           onCancel={handleClose}>
             {
               userEdit.status === 'active'
               ?
               `El usuario ${userEdit?.names} ${userEdit?.surnames} ya no tendrá acceso a la plataforma. Todos sus registros seguirán disponibles.`
               :
               `El usuario ${userEdit?.names} ${userEdit?.surnames} tendrá acceso a la plataforma nuevamente.`
             }
          </DialogConfirm>

          <DialogConfirm
            title={userEdit.status === 'active' || userEdit.status === 'enabled' ? 'Inhabilitar usuario' : 'Habilitar usuario'}
           open={openSuccess}
           type="success"
           onClick={handleCloseSuccess}>
             {
               userEdit.status === 'active'
               ?
               `El usuario ${userEdit?.names} ${userEdit?.surnames} ya no puede acceder a la plataforma.`
               :
               `El usuario ${userEdit?.names} ${userEdit?.surnames} ya puede acceder a la plataforma.`
             }
          </DialogConfirm>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop:'30px', alignItems:' center'}}>
          <Box>
            <Typography
              variant="p"
              noWrap
              component="div"
              >
              Mostrando registros del 1 al 19 un total de 70 registros
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
            <Pagination count={10} variant="outlined" shape="rounded" />
          </Box>
        </Box>
        
    </div>
  );
}

User.propTypes = {
  actions:PropTypes.bool,
  handleChangeCheck: PropTypes.func,
  setChecks: PropTypes.array
};


User.defaultProps = {
  actions: true,
};
import clienteAxios from '../../../config/axios';

export const placesGroupsService = {
    getAll,
    getById,
    create,
    update,
    delete: _delete
};


function getAll() {
    return clienteAxios.get(`/placesgroups`);
}

function getById(id) {
    return clienteAxios.get(`/placesgroups/${id}`);
}

function create(params) {
    return clienteAxios.post(`/placesgroups`, params)
    //return clienteAxios.post('/users/groups', params);
}

function update(id, params) {
    return clienteAxios.put(`/placesgroups/${id}`, params);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(id) {
    return clienteAxios.delete(`/placesgroups/${id}`);
}

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

////////////////////////////////////////////////////////////////////////////////////////////////////


const ButtonTab = (props) => {
    const navigate = useNavigate();

   const [active, setActive] = useState('item-1');

    const setActiveTab = (data, url) => {
        setActive(data)
        navigate(url)
    }

    useEffect(() =>{
        if(props?.active){
            setActive(props?.active)
        }
    }, [active])

   return (
    <div className="container-buttons">
       <div onClick={() => setActiveTab('item-1', props.itemLeft.url)} className={active === 'item-1' ? 'link link-left active' : 'link link-left'}>
            <p>{props.itemLeft.name}</p>
        </div>
        <div onClick={() => setActiveTab('item-2', props.itemRight.url)} className={active === 'item-2' ? 'link link-right active' : 'link link-right'}>
            <p>{props.itemRight.name}</p>
        </div>
    </div>
   )
}


export default ButtonTab;
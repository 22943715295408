import ButtonSearch from "../../atoms/ButtonSearch";
import Icon from "../../atoms/Icon";
import SelectShowItems from "../../../components/molecules/SelectShowItems/index";

////////////////////////////////////////////////////////////////////////////////////////////////////


const SearchFilterLayout = () => {

    return (
        <div className="right-side">
            <div className="search-show">
                    <SelectShowItems pageSize="10" pageSizes={[10,50,100]}></SelectShowItems>
            </div>
            <div className="search-buttons">
                <ButtonSearch></ButtonSearch>
            </div>
            {/* <div className="actions-buttons">
                <Icon icon="mt-filter-two" background="gray" color="black"></Icon>
            </div> */}
        </div>
    )

}

export default SearchFilterLayout;
import React from 'react';
import PropTypes from "prop-types";

////////////////////////////////////////////////////////////////////////////////////////////////////
import Button from '@mui/material/Button';

////////////////////////////////////////////////////////////////////////////////////////////////////


const ButtonSubmit = (props) => (
    <Button 
        className={"btn-group " + (props.type === 'secondary' ? 'btn-secondary' : 'btn-primary')}
        disabled={props.disabled}
        onClick={props.onClick}
        width={props.width+ "px"}
        variant="contained" 
        color="primary"
    >
        {props.title}
    </Button>
)

ButtonSubmit.propTypes = {
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};
  
ButtonSubmit.defaultProps = {
    disabled: false,
};

export default ButtonSubmit;
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PropTypes from 'prop-types';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} 
  arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const CustomizedTooltip = ({...props}) => {
  
  const handleTooltipClose = () => {
    props.open = false
  };

  return (
    <div>
      {props.type === 'tooltip' && 
        <BootstrapTooltip title={props.label}>
            {props.children}
        </BootstrapTooltip>
      }

      {props.type === 'tooltip-fixed' && 
        <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={props.open}
            className={props.width ? 'fix-width' : ''}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            title={props.label}
          >
              {props.children}
        </Tooltip>
      </ClickAwayListener>
      }
        
    </div>
  );
  
}
CustomizedTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool,
  width: PropTypes.string,
  type: PropTypes.string,
};

CustomizedTooltip.defaultProps = {
  type: 'tooltip',
};

export default CustomizedTooltip;
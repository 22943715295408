import React from 'react';
import PropTypes from "prop-types";
import CustomizedTooltip from "../../atoms/Tooltip/index";
////////////////////////////////////////////////////////////////////////////////////////////////////

const Icon = (props) => {
    return (
        <CustomizedTooltip label={props?.tooltip ? props.tooltip : ''}>
            <div onClick={props.onClick} className={props?.background ? `background color-${props?.background} icon-container` : 'icon-container'}>
                <span className={`${props.icon} mt-icon-${props.size} mt-color-${props.color}`}></span>
            </div>
        </CustomizedTooltip>
    );
}

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    size: PropTypes.string,
    background: PropTypes.string,
    tooltip: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
};
  
Icon.defaultProps = {
    size: 'm',
    color: 'gray',
};


export default Icon;
import { Controller } from "react-hook-form";
import React from "react";

////////////////////////////////////////////////////////////////////////////////////////////////////
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

////////////////////////////////////////////////////////////////////////////////////////////////////


const FormSelect = ({ name, control, label, options }) => {
  return (
    <Box mb={2} sx={{ display: 'flex', width: '100%' }}>
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState}) => {
              //console.log(value, label)
            return (
                <TextField
                id="outlined-select-currency"
                select
                defaultValue="none"
                sx={{ marginRight: '10px' }}
                className="inputRounded selectRounded"
                value={value ?? "none"}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onChange={onChange}
                >
                <MenuItem key="0" disabled value="none" >{label}</MenuItem>
                {options.map((option) => (
                    <MenuItem key={option._id || option.value } value={option._id  || option.value}>
                    {option.name}
                    </MenuItem>
                ))}
                </TextField>
            )}}
        />
    </Box>
  );
};



export default FormSelect;
import { useForm, Controller } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { placesService } from '../../services/places.service';
import PageLayout from '../../../../components/atoms/PageLayout/index';
import Icon from '../../../../components/atoms/Icon/index';
import FormInputText from '../../../../components/atoms/FormInput/index';
import { useParams } from 'react-router-dom';
import ButtonSubmit from '../../../../components/atoms/Button/index';
import ButtonSearch from '../../../../components/atoms/ButtonSearch/index';
import Users from '../../../Users/pages/Overview/index';
import MapContainer from '../../../../components/implementation/Map/index';
import Toaster from '../../../../components/atoms/Toast';
import SearchFilterLayout from '../../../../components/molecules/SearchFilterLayout/index';
////////////////////////////////////////////////////////////////////////////////////////////////////
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { TabContext } from '@mui/lab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { placesGroupsService } from '../../../PlacesGroups/services/placegroup.service';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

////////////////////////////////////////////////////////////////////////////////////////////////////

const schema = yup
  .object({
    name: yup.string().required('Campo obligatorio'),
    detail: yup.string(),
    country: yup.string().required('Campo obligatorio'),
    department: yup.string().required('Campo obligatorio'),
    province: yup.string().required('Campo obligatorio'),
    district: yup.string().required('Campo obligatorio'),
    latitude: yup.string().required('Campo obligatorio'),
    longitude: yup.string().required('Campo obligatorio'),
    groupplaces: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string(),
          label: yup.string(),
        })
      )
      .min(1, 'Por favor seleccione al menos un grupo'),
  })
  .required();

const CreatePlace = (props) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [placesGroup, setPlacesGroups] = useState([]);
  const [mode, setMode] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [groupvalue, setGroupValue] = useState([]);
  const [usersIDs, setUsersIDs] = useState([]);
  const { control, handleSubmit, reset, formState, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  const [openGroup, toggleOpenGroup] = useState(false);
  const [dialogGroupValue, setDialogGroupValue] = useState({
    name: '',
  });
  const [newValue, setNewValue] = useState(null);

  useEffect(() => {
    if (errors) {
      setTab('1');
    }
  }, [errors]);

  const onSubmit = handleSubmit((data) => {
    data.users = usersIDs;
    data.placeGroups = groupvalue.map((group) => group._id);
    //console.log(data)
    if (props.mode === 'edit') {
      return placesService
        .update(data._id, data)
        .then(() => {
          setOpenToast(true);
        })
        .catch(() => console.log('error'));
    } else {
      return placesService
        .create(data)
        .then((data) => {
          setOpenToast(true);
          setMode(true);
          navigate(`/places/edit/${data?.data._id}`, { mode: 'edit' });
          setTimeout(() => {
            setOpenToast(false);
          }, 5000);
        })
        .catch(() => console.log('error'));
    }
  });

  useEffect(() => {
    const fetchPlacesGroups = async () =>
      placesGroupsService.getAll().then((response) => {
        if (response) {
          setPlacesGroups(response.data);
        }
      });

    fetchPlacesGroups();
  }, []);

  useEffect(() => {
    if (props.mode === 'edit') {
      return placesService.getById(id).then((response) => {
        if (response) {
          reset(response?.data);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (mode && id) {
      return placesService.getById(id).then((response) => {
        if (response) {
          reset(response?.data);
        }
      });
    }
  }, [id, mode]);

  const [tab, setTab] = useState('1');

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const openDialog = () => {
    toggleOpenGroup(true);
    setDialogGroupValue({
      name: newValue,
    });
  };

  const handleCloseGroup = (data) => {
    setDialogGroupValue({
      name: '',
    });

    toggleOpenGroup(false);
  };

  const onChangeHandle = (newValue) => {
    setNewValue(newValue);
  };

  const handleDelete = (data) => {
    setGroupValue((group) => group.filter((item) => item._id !== data._id));
  };

  const handleSubmitGroup = (event) => {
    event.preventDefault();
    let name = {
      name: dialogGroupValue.name,
    };
    placesGroupsService.create(name).then((response) => {
      if (response) {
        setGroupValue((group) => group.concat([response.data]));
        setPlacesGroups((group) => group.concat([response.data]));
      }
    });
    handleCloseGroup();
  };

  const onDataChange = (data) => {
    let filtered = data.filter((user) => user.selected).map((usr) => usr._id);
    setUsersIDs(filtered);
  };

  const onMarkerChange = (data) => {
    console.log('On create', data);
    setValue('latitude', data?.lat.toFixed(4), { shouldValidate: true });
    setValue('longitude', data?.lng.toFixed(4), { shouldValidate: true });
  };
  return (
    <PageLayout>
      <Toaster
        open={openToast}
        message={
          mode || props.mode === 'edit'
            ? 'Se ha actualizado el lugar con éxito'
            : 'Se ha creado el lugar con éxito'
        }
      ></Toaster>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
        <div>
          <h2>Nuevo lugar</h2>
        </div>
        <form className="form-create-user" onSubmit={onSubmit}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: '#00ffff' }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                centered
              >
                <Tab
                  className="space-right"
                  icon={
                    <Icon
                      size="s"
                      icon="mt-answer-correct"
                      color="black"
                    ></Icon>
                  }
                  label="Datos del lugar"
                  value="1"
                />
                <Tab
                  className="space-right"
                  icon={
                    <Icon size="s" icon="mt-marker-map" color="black"></Icon>
                  }
                  label="Usuarios"
                  value="2"
                />
              </TabList>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box
                sx={{
                  height: 'auto',
                  width: '100%',
                  padding: '10px',
                  marginRight: '10px',
                  marginLeft: '-10px',
                }}
              >
                <TabPanel value="1" sx={{ padding: '0' }}>
                  <Box sx={{ display: 'flex', width: '100%' }}>
                    <Box
                      mb={2}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        paddingTop: '20px',
                      }}
                    >
                      <Box
                        mb={2}
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                          marginRight: '10px',
                        }}
                      >
                        <FormInputText
                          name="name"
                          control={control}
                          label="Nombre de Lugar"
                        ></FormInputText>

                        <FormInputText
                          name="detail"
                          control={control}
                          label="Detalle de Lugar"
                        ></FormInputText>
                      </Box>
                      <Box
                        mb={2}
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                        }}
                      >
                        <FormInputText
                          name="country"
                          control={control}
                          label="País"
                        ></FormInputText>

                        <FormInputText
                          name="department"
                          control={control}
                          label="Departamento"
                        ></FormInputText>
                      </Box>

                      <Box
                        mb={2}
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                        }}
                      >
                        <FormInputText
                          name="province"
                          control={control}
                          label="Provincia"
                        ></FormInputText>

                        <FormInputText
                          name="district"
                          control={control}
                          label="Distrito"
                        ></FormInputText>
                      </Box>

                      <Box
                        mb={2}
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                        }}
                      >
                        <Box
                          mb={2}
                          sx={{
                            display: 'flex',
                            width: '50%',
                            flexDirection: 'row',
                          }}
                        >
                          <Box sx={{ width: '100%', marginRight: '10px' }}>
                            <Controller
                              name="groupplaces"
                              control={control}
                              defaultValue={[]}
                              render={({
                                field: { onChange, value },
                                fieldState,
                              }) => (
                                <Autocomplete
                                  multiple
                                  value={groupvalue}
                                  options={placesGroup}
                                  onChange={(event, value) => {
                                    setGroupValue(value);
                                    onChange(value);
                                  }}
                                  renderTags={() => null}
                                  disableClearable
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => option.name}
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => (
                                    <li {...props}>
                                      <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.name}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      onChange={(event) => {
                                        if (
                                          event.target.value !== '' ||
                                          event.target.value !== null
                                        ) {
                                          onChangeHandle(event.target.value);
                                        }
                                      }}
                                      error={!!fieldState.error}
                                      helperText={fieldState.error?.message}
                                      className="inputRounded selectRounded"
                                      placeholder="Grupo de lugares"
                                    />
                                  )}
                                />
                              )}
                              rules={{ required: 'grupo de usuarios required' }}
                            />

                            <Box
                              sx={{
                                display: 'flex',
                                width: '100%',
                                marginTop: '10px',
                              }}
                            >
                              {groupvalue.map((option) => (
                                <Chip
                                  label={option.name}
                                  onDelete={() => handleDelete(option)}
                                />
                              ))}
                            </Box>
                          </Box>
                          <Box>
                            <Icon
                              onClick={() => openDialog()}
                              icon="mt-plus"
                              background="gray"
                              color="black"
                            ></Icon>
                          </Box>
                          <Dialog open={openGroup} onClose={handleCloseGroup}>
                            <form>
                              <DialogTitle>
                                Agregar nuevo grupo de usuario
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText>
                                  No encuentras el grupo? Por favor, agrégalo!
                                </DialogContentText>
                                <TextField
                                  autoFocus
                                  margin="dense"
                                  id="name"
                                  value={dialogGroupValue.name}
                                  onChange={(event) =>
                                    setDialogGroupValue({
                                      ...dialogGroupValue,
                                      name: event.target.value,
                                    })
                                  }
                                  label="title"
                                  type="text"
                                  variant="standard"
                                />
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleCloseGroup}>
                                  Cancelar
                                </Button>
                                <Button
                                  onClick={handleSubmitGroup}
                                  type="button"
                                >
                                  Agregar
                                </Button>
                              </DialogActions>
                            </form>
                          </Dialog>
                        </Box>
                      </Box>

                      <Box
                        mb={2}
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'row',
                        }}
                      >
                        <Box
                          mb={2}
                          sx={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                          }}
                        >
                          <Box
                            mb={2}
                            sx={{
                              display: 'flex',
                              width: '100%',
                              flexDirection: 'row',
                            }}
                          >
                            <ButtonSearch></ButtonSearch>
                          </Box>
                          <Box
                            mb={2}
                            sx={{
                              display: 'flex',
                              width: '100%',
                              flexDirection: 'row',
                            }}
                          >
                            <FormInputText
                              name="longitude"
                              control={control}
                              label="Longitud"
                            ></FormInputText>
                          </Box>
                          <Box
                            mb={2}
                            sx={{
                              display: 'flex',
                              width: '100%',
                              flexDirection: 'row',
                            }}
                          >
                            <FormInputText
                              name="latitude"
                              control={control}
                              label="Latitud"
                            ></FormInputText>
                          </Box>
                        </Box>
                        <Box
                          mb={2}
                          sx={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'row',
                          }}
                        >
                          <MapContainer
                            onDragEnd={onMarkerChange}
                          ></MapContainer>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel value="2">
                  <Box
                  mb={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <div className="container-end">
                      <SearchFilterLayout></SearchFilterLayout>
                    </div>
                  </Box>

                  <Users
                    actions={false}
                    handleChangeCheck={onDataChange}
                  ></Users>
                </TabPanel>
              </Box>
            </Box>
          </TabContext>
          <Box
            mb={2}
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Box mb={2} sx={{ marginRight: '10px' }}>
              <ButtonSubmit
                onClick={() => navigate('/places')}
                title="Atrás"
                type="secondary"
              ></ButtonSubmit>
            </Box>
            <Box mb={2} sx={{ marginRight: '10px' }}>
              {props.mode === 'edit' ? (
                <ButtonSubmit
                  onClick={onSubmit}
                  title="Actualizar"
                ></ButtonSubmit>
              ) : (
                <ButtonSubmit onClick={onSubmit} title="Guardar"></ButtonSubmit>
              )}
            </Box>
          </Box>
        </form>
      </Box>
    </PageLayout>
  );
};

export default CreatePlace;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userGroupsService } from './services/user-groups.service';
////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Icon from '../../components/atoms/Icon';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import CustomizedTooltip from "../../components/atoms/Tooltip/index";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#dddddd',
    color: '#000',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function UserGroups() {
  const navigate = useNavigate();
  const [APIData, setAPIData] = useState([]);

  useEffect(() => {
    return userGroupsService
      .getAll()
      .then((response) => {
        setAPIData(response?.data);
      })
      .catch(() => console.log('error!'));
  }, []);

  const onEdit = (id) => {
    navigate('/users/groups/edit/' + id);
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <TableContainer className="table-custom" component={Paper}>
          <Table sx={{ minWidth: 300 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>ID</StyledTableCell>
                <StyledTableCell>Grupo</StyledTableCell>
                <StyledTableCell>Descripción del grupo</StyledTableCell>
                <StyledTableCell>Cantidad de usuarios</StyledTableCell>
                <StyledTableCell>Acciones</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {APIData.map((data) => (
                <StyledTableRow
                  key={data._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="data">
                    {data.id}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="data">
                    {data.name}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="data">
                    {data.description || '-'}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="data">
                    {data.users?.length || '-'}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="data">
                    <Box>
                      <CustomizedTooltip label="Editar">
                        <IconButton
                          onClick={() => onEdit(data._id)}
                          aria-label="delete"
                        >
                          <Icon icon="mt-lapiz-box" color="black"></Icon>
                        </IconButton>
                      </CustomizedTooltip>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '30px',
          alignItems: ' center',
        }}
      >
        <Box>
          <Typography variant="p" noWrap component="div">
            Mostrando registros del 1 al 19 un total de 70 registros
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Pagination count={10} variant="outlined" shape="rounded" />
        </Box>
      </Box>
    </div>
  );
}

import React from 'react';
import logo from "../../../assets/images/logo-text-w.svg"
import load from "../../../assets/images/mt-load-w.png"
import AppLinks from "../../atoms/AppLinks/index";
import ButtonSubmit from "../../atoms/Button/index";
import Icon from "../../atoms/Icon/index";
import {
  Link,
  useLocation
} from "react-router-dom";
////////////////////////////////////////////////////////////////////////////////////////////////////

import { useNavigate } from 'react-router-dom';
import { useAuthState, useAuthDispatch } from '../../../features/Auth/context/authContext'

////////////////////////////////////////////////////////////////////////////////////////////////////

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import Notifications from '@mui/icons-material/Notifications';
import MoreVert from '@mui/icons-material/MoreVert';


const Header = () => {

    const { user } = useAuthState();
    const location = useLocation();
    const navigate = useNavigate();
    const authDispatch = useAuthDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    //console.log(splitLocation)
    
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
        authDispatch.logout() 
        navigate('/')
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
      <Menu
        className="menu-header"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={menuId}
        keepMounted
        PaperProps={{
          style: {
           marginTop: "60px"
          }
         }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <div className="header-pop">
          <p>OLFM</p>
        </div>
        <MenuItem onClick={handleMenuClose}>
          <IconButton sx={{ paddingTop: '0'}} size="large" aria-label="show 4 new mails" color="inherit">
            <EmailOutlined />
          </IconButton>
          <div className="container-option">
            <p className="title-menu">Correo</p>
            <div><span className="subtitle-menu">{user?.email}</span></div>
          </div>

        </MenuItem>
        
        <MenuItem onClick={handleMenuClose}>
          <IconButton sx={{ paddingTop: '0'}} size="large" aria-label="show 4 new mails" color="inherit">
            <Icon icon="mt-box-user"></Icon>
          </IconButton>
          <div className="container-option">
            <p className="title-menu">Cargo</p>
            <div><span className="subtitle-menu">{user?.position}</span></div>
          </div>
         
          
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <div className="hyperlik-gray container-edit-profile">
            <Link className="hyperlink-gray" to="/profile/edit">Editar perfil</Link>
          </div>

        </MenuItem>
        <Box sx={{ display: 'flex', width: '80%', margin:'0 20px' }}>
          <div className="horizontal-line"></div>
        </Box>
        <MenuItem>
          <Box mb={1}  mt={2} sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
            <ButtonSubmit 
                onClick={handleLogout} 
                title="Cerrar Sesión" 
                width="130" 
                >
            </ButtonSubmit>
          </Box>
        </MenuItem>
      </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton size="large" aria-label="show 4 new mails" color="inherit">
            <Badge badgeContent={4} color="error">
              <EmailOutlined />
            </Badge>
          </IconButton>
          <p>Messages</p>
        </MenuItem>
        <MenuItem>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge badgeContent={17} color="error">
              <Notifications />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
        <MenuItem onClick={handleLogout}>Salir</MenuItem>
      </Menu>
    );

    return ( 
        <header className="header-background">
          {
                    user ? (
                      <div>
                        <Box sx={{ flexGrow: 1 }}>
                        <AppBar position="static">
                          <Toolbar>
                          <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center'}}>
                                    <img onClick={() => navigate('/users')}  src={logo} alt="logo" className="logo" />
                                    <img onClick={() => navigate('/users')}  src={load} alt="logo" className="logo-icon" />
                                    <Typography
                                    variant="h6"
                                    noWrap
                                    component="div"
                                    sx={{ display: { xs: 'none', sm: 'block' } }}
                                    >
                                    </Typography>
                          </Box>
                          <Box  sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', width: '60%', justifyContent: 'space-between'}}>
                              <Box sx={{ display: 'flex', alignItems: 'center'}}>
                                  <img onClick={() => navigate('/users')}  src={logo} alt="logo" className="logo" />
                                  <img onClick={() => navigate('/users')}  src={load} alt="logo" className="logo-icon" />
                                  <Typography
                                  variant="h6"
                                  noWrap
                                  component="div"
                                  sx={{ display: { xs: 'none', sm: 'block' } }}
                                  >
                                  </Typography>
                              </Box>
                              <Box>
                                  <AppLinks splitLocation={splitLocation}></AppLinks>
                              </Box>
                          </Box>
                          <Box sx={{ flexGrow: 1 }} />
                          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                <IconButton onClick={() => navigate('/users')}  size="large" color="inherit">
                                    <Icon icon="mt-user-body" size="l" color={splitLocation[1] === "users" ? "blue" : ""}></Icon>
                                </IconButton>

                              <IconButton
                                onClick={() => navigate('/places')}
                                size="large"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                color="inherit"
                              >
                                <Icon icon="mt-marker" size="l" color={splitLocation[1] === "places" ? "blue" : ""}></Icon>
                              </IconButton>

                              <IconButton
                                size="large"
                                color="inherit"
                              >
                                <Icon icon="mt-evaluation" size="l"></Icon>
                              </IconButton>

                            <div className="vertical-line"></div>
                            <Typography
                              variant="h1"
                              noWrap
                              component="h1"
                              sx={{ display: { xs: 'none', sm: 'block' } }}
                              >
                                {user?.names} {user?.surnames}
                            </Typography>
                            <IconButton
                              size="large"
                              edge="end"
                              aria-label="account of current user"
                              aria-controls={menuId}
                              aria-haspopup="true"
                              onClick={handleProfileMenuOpen}
                              color="inherit"
                            >
                              <AccountCircle />
                            </IconButton>
                          </Box>
                          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                              size="large"
                              aria-label="show more"
                              aria-controls={mobileMenuId}
                              aria-haspopup="true"
                              onClick={handleMobileMenuOpen}
                              color="inherit"
                            >
                              <MoreVert />
                            </IconButton>
                          </Box>
                          </Toolbar>
                        </AppBar>
                        {renderMobileMenu}
                        {renderMenu}
                      </Box>
                      </div>
                    )
                    : ('No hay user')
                  
            }
            {/* <div>
                {
                    user ? (

                        <Box sx={{ flexGrow: 1 }}>
                        <AppBar position="static">
                          <Toolbar>
                          <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center'}}>
                                    <img onClick={() => navigate('/users')}  src={logo} alt="logo" className="logo" />
                                    <img onClick={() => navigate('/users')}  src={load} alt="logo" className="logo-icon" />
                                    <Typography
                                    variant="h6"
                                    noWrap
                                    component="div"
                                    sx={{ display: { xs: 'none', sm: 'block' } }}
                                    >
                                    </Typography>
                            </Box>
                            <Box  sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', width: '60%', justifyContent: 'space-between'}}>
                                <Box sx={{ display: 'flex', alignItems: 'center'}}>
                                    <img onClick={() => navigate('/users')}  src={logo} alt="logo" className="logo" />
                                    <img onClick={() => navigate('/users')}  src={load} alt="logo" className="logo-icon" />
                                    <Typography
                                    variant="h6"
                                    noWrap
                                    component="div"
                                    sx={{ display: { xs: 'none', sm: 'block' } }}
                                    >
                                    </Typography>
                                </Box>
                                <Box>
                                    <AppLinks splitLocation={splitLocation}></AppLinks>
                                </Box>
                            </Box>
                            <Box sx={{ flexGrow: 1 }} />
                            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                  <IconButton onClick={() => navigate('/users')}  size="large" color="inherit">
                                      <Icon icon="mt-user-body" size="l" color={splitLocation[1] === "users" ? "blue" : ""}></Icon>
                                  </IconButton>

                                <IconButton
                                  onClick={() => navigate('/places')}
                                  size="large"
                                  aria-controls={menuId}
                                  aria-haspopup="true"
                                  color="inherit"
                                >
                                  <Icon icon="mt-marker" size="l" color={splitLocation[1] === "places" ? "blue" : ""}></Icon>
                                </IconButton>

                                <IconButton
                                  size="large"
                                  color="inherit"
                                >
                                  <Icon icon="mt-evaluation" size="l"></Icon>
                                </IconButton>

                              <div className="vertical-line"></div>
                              <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ display: { xs: 'none', sm: 'block' } }}
                                >
                                  {user?.names} {user?.surnames}
                              </Typography>
                              <IconButton
                                size="large"
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                              >
                                <AccountCircle />
                              </IconButton>
                            </Box>
                            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                              <IconButton
                                size="large"
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                              >
                                <More />
                              </IconButton>
                            </Box>
                          </Toolbar>
                        </AppBar>
                        {renderMobileMenu}
                        {renderMenu}
                      </Box>
                    ) : (
                        <>
                            <div className="flex items-center">
                                <div>Need to login</div>
                            </div>
                        </>
                    )
                }

            </div> */}
        </header>
     );
}
 
export default Header;
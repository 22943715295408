import PropTypes from "prop-types";

////////////////////////////////////////////////////////////////////////////////////////////////////
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const SelectShowItems = (props) => {
    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', width:'200px', marginRight: '10px'}}>
                <Typography
                    variant="p"
                    noWrap
                    component="p"
                    sx={{width: '80%', fontSize:'16px !important'}}

                    >
                    Mostrar
                </Typography>
                <TextField
                    id="outlined-select-currency"
                    select
                    className="inputRounded selectRounded"
                    value={props.pageSize}
                >
                    {props.pageSizes.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                    ))}
                </TextField>
            </Box>
        </div>
    )

}

SelectShowItems.propTypes = {
    pageSize: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};
 
SelectShowItems.defaultProps = {
    pageSize: '10',
};
export default SelectShowItems;
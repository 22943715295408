import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';

const Toaster = ({open, type, message}) => {
    useEffect(() => {
        if(open) {
            switch(type) {
                case 'success':
                    toast.success(message,{
                        toastId: 'success1',
                    })
                    break;
                case 'error':
                    toast.error(message,{
                        toastId: 'error1',
                    })
                    break;
                case 'warning':
                    toast.warning(message,{
                        toastId: 'warning1',
                    })
                    break;
                default:
                    toast.success(message,{
                        toastId: 'warning1',
                    })
                    break;
            }
        }else{
            toast.dismiss('success1')
        }
    });

    return (
        <div>
          <ToastContainer />
        </div>
    );
}

Toaster.prototype = {
    open: PropTypes.bool.isRequired,
    type: PropTypes.string,
    message: PropTypes.string
}

Toaster.defaultProps = {
    type: 'success',
    open: false,
}


export default Toaster;
import React from 'react';
import {
    Link,
  } from "react-router-dom";
////////////////////////////////////////////////////////////////////////////////////////////////////


const AppLinks = (props) => {
    return (
        <ul className="menu-links">
            <li className={props.splitLocation[1] === "activities" ? "active menu-item" : "menu-item"}>  
                <Link to='/activities'>Actividades</Link>
            </li>
            <li  className={props.splitLocation[1] === "attendaces" ? "active menu-item" : "menu-item"}>
                <Link to='/attendaces'>Asistencias</Link>
            </li>
            <li  className={props.splitLocation[1] === "locations" ? "active menu-item" : "menu-item"}>
                <Link to='/locations'>Localizacion</Link>
            </li>
        </ul>
    )
}

export default AppLinks;
import { 
  Outlet,
} from 'react-router-dom';
import ActionsLayout from "../../components/molecules/ActionsLayout";

////////////////////////////////////////////////////////////////////////////////////////////////////

export default function User() {
 
  return (
    <div>
      <ActionsLayout >
        <Outlet />
      </ActionsLayout>
      
    </div>
  );
}

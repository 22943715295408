import clienteAxios from '../../../config/axios';

export const placesService = {
    getAll,
    getById,
    create,
    update,
    delete: _delete,
    blockPlace
};


function getAll() {
    return clienteAxios.get('/places');
}

function getById(id) {
    return clienteAxios.get(`/places/${id}`);
}

function create(params) {
    return clienteAxios.post('/places', params);
}

function update(id, params) {
    return clienteAxios.put(`/places/${id}`, params);
}

function blockPlace(id, params) {
    return clienteAxios.patch(`/places/${id}/isBlocked`, params);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(id) {
    return clienteAxios.delete(`/places/${id}`);
}



import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import { userGroupsService } from "../../services/user-groups.service";
import ButtonSubmit from "../../../../components/atoms/Button/index";
import Users from "../../../Users/pages/Overview/index";
import { useState, useEffect } from "react";
import SearchFilterLayout from "../../../../components/molecules/SearchFilterLayout/index";
import { useParams } from 'react-router-dom';
import FormInputText from "../../../../components/atoms/FormInput/index";
import TextArea from "../../../../components/atoms/TextArea/index";
import Toaster from "../../../../components/atoms/Toast";
////////////////////////////////////////////////////////////////////////////////////////////////////
import Box from '@mui/material/Box';
////////////////////////////////////////////////////////////////////////////////////////////////////


const schema = yup.object({
  name: yup.string().required('Este campo es requerido'),
  description: yup.string(),
}).required();

const Create = (props) => {
    const [name, setName] = useState("");
    const [users, setUsers] = useState("");
    const [mode, setMode] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    let { id } = useParams();

    const navigate = useNavigate();
    const [usersIDs, setUsersIDs] = useState([])

    const { control,reset, handleSubmit } = useForm({
      resolver: yupResolver(schema)
    });

    const onSubmit = handleSubmit(data => {
        data.users = usersIDs
        if(props?.mode === 'edit'){
          return userGroupsService.update(data._id, data)
          .then((response) => {
            setOpenToast(true)
          })
          .catch(() => console.log("error"));
        }else {
          return userGroupsService.create(data)
          .then((data) => {
              //console.log(data)
              setOpenToast(true)
              setMode(true)
              navigate(`/users/groups/edit/${data?.data._id}`,  { mode: 'edit' })
              //navigate('/places/groups')
              setTimeout(() => {
                setOpenToast(false)
              }, 5000);
          })
          .catch(() => console.log("error"));
        }
    })

    const onDataChange = (data) => {
      let filtered = data.filter(user => user.selected).map(usr => usr._id)
      setUsersIDs(filtered);
    }

    useEffect(() => {
      if(props?.mode === 'edit'){
        //console.log(JSON.stringify(id));
        userGroupsService.getById(id)
          .then((response) => {
            console.log(response.data)
            reset(response?.data );
            setUsers(response?.data?.users)
            
          })
          .catch(() => console.log("error"));
      }
    }, [name])


    useEffect(() => {
      if(mode && id){
        userGroupsService.getById(id)
          .then((response) => {
            console.log(response.data)
            reset(response?.data );
            setUsers(response?.data?.places)
          })
          .catch((e) => console.log("error", e));
      }
    }, [id, mode])


    
    
    return (
      <div className="container-layout">
        <Toaster open={openToast} message={mode || props.mode === 'edit' ? 'Se ha actualizado el grupo con éxito': 'Se ha creado el grupo con éxito'}></Toaster>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
          <div className="row">
            <div>
              {props.mode === 'edit'
              ? (<h2>Editar grupo usuarios</h2>)
              : (<h2>Nuevo grupo de usuarios</h2>)   
              }
              
              <form className="form-groups" onSubmit={onSubmit}>
                <Box sx={{ display: 'flex', width: '70%' }}>
                    <FormInputText
                      name="name"
                      control={control}
                      label="Nombre de grupo"
                      >
                    </FormInputText>
                  </Box>
                  <Box sx={{ display: 'flex', width: '120%' }}>
                    <TextArea
                      name="description"
                      control={control}
                      label="Descripción"
                      rows={5}
                      >
                    </TextArea>
                    
                  </Box>
              </form>
            </div>
            <div className="right-side-search container-end">
              <SearchFilterLayout></SearchFilterLayout>
            </div>
          </div>
         
          <Users actions={false} setChecks={users} idGroup={id} handleChangeCheck={onDataChange}></Users>
          <Box mb={2} sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginTop:'30px' }}>

                <Box mb={2} sx={{ marginRight: '10px' }}>
                  <ButtonSubmit 
                    onClick={() => navigate('/users/groups')} 
                    title="Atrás"
                    type="secondary" 
                  >
                  </ButtonSubmit>
                </Box>
                <Box mb={2} sx={{ marginRight: '10px' }}>
                  {props.mode === 'edit'
                    ? (
                      <ButtonSubmit 
                        onClick={onSubmit} 
                        title="Actualizar" 
                        >
                      </ButtonSubmit>
                    )
                    : (
                      <ButtonSubmit 
                        onClick={onSubmit} 
                        title="Guardar" 
                        >
                      </ButtonSubmit>
                    )   
                  }
                </Box>
            </Box>

        </Box>
      </div>
      
      
    );
}
export default Create;

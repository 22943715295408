import { 
  Outlet,
} from 'react-router-dom';
import ActionsLayout from "../../components/molecules/ActionsLayout";

////////////////////////////////////////////////////////////////////////////////////////////////////

export default function Places() {

  return (
    <div>
      <ActionsLayout>
        <Outlet />
      </ActionsLayout>
      
    </div>
  );
}

import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from "prop-types";
import Backdrop from '@mui/material/Backdrop';

const Spinner = ({active}) => {
  return (
      <div>
        { active && 
           <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={active}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        }
      </div>
    
  );
}


Spinner.propTypes = {
    active: PropTypes.bool.isRequired,
};
  
Spinner.defaultProps = {
    active: false,
};

export default Spinner;
import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
//import SearchBox from '../SearchMap/index';
import AppConfig from '../../../configuration';

const mapStyles = {
  width: '40%',
  height: '30%'
};

export class MapContainer extends Component {
    state = {
        showingInfoWindow: false,  // Hides or shows the InfoWindow
        activeMarker: {},          // Shows the active marker upon click
        selectedPlace: {},          // Shows the InfoWindow to the selected place upon a marker
        mapsApiLoaded: false,
        mapInstance: null,
        mapsapi: null,
    };
    
    onMarkerClick = (props, marker, e) => {
        this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: false,
        });
    }

    onClose = props => {
        if (this.state.showingInfoWindow) {
        this.setState({
            showingInfoWindow: false,
            activeMarker: null
        });
        }
    };

    onMarkerDragEnd = (coord, ) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        //console.log(lat, lng);
        this.props.onDragEnd({lat, lng});
        // this.setState(prevState => {
        //   const markers = [...this.state.markers];
        // });
    };


    apiLoaded= (map, maps) => {
        console.log(map, maps);
        this.setState({
        mapsApiLoaded: true,
        mapInstance: map,
        mapsapi: maps,
        });
    }
  
    
    render() {
        return (
            <Map
            google={this.props.google}
            zoom={14}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
                this.apiLoaded(map, maps);
            }}
            style={mapStyles}
            initialCenter={
                {
                    lat: -12.0460407,
                    lng:  -77.0304114
                }
            }
            >
                <Marker
                    onClick={this.onMarkerClick}
                    draggable={true}
                    onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
                />
            </Map>
            
        );
    }
}

export default GoogleApiWrapper({
    apiKey: AppConfig.map.apiKey,
    libraries: ['places'],
})(MapContainer);
const NotAllowed = () => {

    return (
        <div className="page">
            User not NotAllowed
        </div>
       
      
      
    );
}
export default NotAllowed;

import clienteAxios from '../../../config/axios';

export const userService = {
    getAll,
    getById,
    create,
    update,
    delete: _delete,
    getAllPositions,
    getAllCompanies,
    blockUser,
    addPosition,
    addGroup,
    checkDuplicateEmail
};


function getAll() {
    return clienteAxios.get('/users');
}

function getById(id) {
    return clienteAxios.get(`/users/${id}`);
}

function create(params) {
    return clienteAxios.post('/users', params);
}

function update(id, params) {
    return clienteAxios.put(`/users/${id}`, params);
}

function blockUser(id, params) {
    return clienteAxios.patch(`/users/${id}/status`, params);
}


// prefixed with underscored because delete is a reserved word in javascript
function _delete(id) {
    return clienteAxios.delete(`/users/${id}`);
}

function addPosition(params) {
    return clienteAxios.post(`/positions`, params);
}

function addGroup(params) {
    return clienteAxios.post(`/usersgroups`, params);
}

function getAllPositions() {
    return clienteAxios.get(`/positions`);
}

function getAllCompanies() {
    return clienteAxios.get(`/companies`);
}

function checkDuplicateEmail(params) {
    return clienteAxios.post(`/users/ValidateEmail`, params);
}


import { useForm, Controller} from "react-hook-form";
import { useState, useEffect, createRef } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import { userService } from "../../services/user.service";
import { userGroupsService } from "../../../UsersGroups/services/user-groups.service";
import ButtonSubmit from "../../../../components/atoms/Button/index";
import PageLayout from "../../../../components/atoms/PageLayout/index";
import Icon from "../../../../components/atoms/Icon/index"
import emptyUser from "../../../../assets/user/user.png"
import FormInputText from "../../../../components/atoms/FormInput/index";
import FormSelect from "../../../../components/atoms/FormSelect/index";
import { useParams } from 'react-router-dom';
import Places from "../../../Places/pages/Overview/index";
import SearchFilterLayout from "../../../../components/molecules/SearchFilterLayout/index";
import Toaster from "../../../../components/atoms/Toast";
////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////////////////////////////////////////////////////
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Tab from '@mui/material/Tab';
import { TabContext } from '@mui/lab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import CameraAlt from '@mui/icons-material/CameraAlt';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

////////////////////////////////////////////////////////////////////////////////////////////////////

const schema = yup.object({
  names: yup.string().required('Por favor ingrese el nombre'),
  email:  yup.string()
  .email('Por favor ingrese un correo válido')
  .required('Por favor ingrese el correo'),
  documentType:  yup.string().required('Por favor ingrese el tipo de documento'),
  tracking:  yup.string().required('Por favor ingrese la localización'),
  surnames:  yup.string().required('Por favor ingrese el apellido'),
  role:  yup.string().required('Por favor ingrese el rol'),
  company:  yup.string().required('Por favor ingrese la empresa'),
  documentNumber:  yup.string().required('Por favor ingrese el número de documento'),
  position:  yup.string().required('Por favor ingrese el cargo'),
  modules: yup.object().shape({
    activities: yup.boolean().default(false),
    attendance: yup.boolean().default(false),
  })
  .test(
    'permissions',
    null,
    (obj) => {
      if ( obj.activities || obj.attendance ) {
        return true; // everything is fine
      }

      return new yup.ValidationError(
        'Please check one checkbox',
        null,
        'permissions'
      );
    },
  ),
  users: yup.array().min(1).required(),
  groupvalue: yup.array()
  .of(
    yup.object().shape({
      value: yup.string(),
      label: yup.string()
    })
  )
  .min(1, "Por favor seleccione al menos un grupo"),
}).required();


const checkDuplicate = async(valueToCheck) => {
  return new Promise(async (resolve, reject) => {
    let isDuplicateExists;
    resolve(true)
   
  });
}

const Create = (props) => {
    const [modules, setPermissions] = useState({activities: false, attendance: false});
    const [newValue, setNewValue] = useState(null);
    const [type, setType] = useState("success");
    const [placesIDs, setPlacesIDs] = useState([]);
    const [newValuePosition, setNewValuePosition] = useState(null);
    const [userData, setUserData] = useState([]);
    const [avatarUrl, setAvatarUrl] = useState("");
    const [groupvalue, setGroupValue] = useState([]);
    const [open, toggleOpen] = useState(false);
    const [openGroup, toggleOpenGroup] = useState(false);
    const [position, setPosition] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [usersgroups, setUsersGroups] = useState([]);
    const [mode, setMode] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [message, setMessage] = useState(false);
    const { control, handleSubmit, reset, formState, setValue } = useForm({
      resolver: yupResolver(schema)
    });
    const { errors } = formState;
    const navigate = useNavigate();
    let { id } = useParams();
    const inputFileRef = createRef(null);

    useEffect(() => {
      console.log(errors)
      if (errors && (errors.names || errors.email || errors.documentType || errors.tracking || errors.surnames || errors.role || errors.documentNumber || errors.position)) {
        setType('error')
        setMessage("Por favor ingrese todos los campos requeridos")
        setOpenToast(true)
        setTimeout(() => {
          setOpenToast(false)
        }, 5000);
        setTab("1");
      }else if (!(errors.names && errors.email && errors.documentType && errors.tracking && errors.surnames && errors.role && errors.documentNumber && errors.position) && errors.users) {
        setType('error')
        setMessage("Por favor seleccione al menos un lugar")
        setOpenToast(true)
        setTimeout(() => {
          setOpenToast(false)
        }, 5000);
        setTab("2");
      }else if(!(errors.names && errors.email && errors.documentType && errors.tracking && errors.surnames && errors.role && errors.documentNumber && errors.position && errors.users)  && errors.permissions) {
        setType('error')
        setMessage("Por favor seleccione al menos un permiso")
        setOpenToast(true)
        setTimeout(() => {
          setOpenToast(false)
        }, 5000);
        setTab("3");
      }
    }, [errors]);

  

    
    const handleClose = () => {
      setDialogValue({
        name: '',
      });
  
      toggleOpen(false);
    };

    const handleCloseGroup = (data) => {
      setDialogGroupValue({
        name: '',
      });
  
      toggleOpenGroup(false);
    };
    const [dialogValue, setDialogValue] = useState({
      name: '',
    });

    const [dialogGroupValue, setDialogGroupValue] = useState({
      name: '',
    });

    const onSubmit = handleSubmit(data => {
        data.placeGroups = placesIDs
        data.userGroups = groupvalue.map(group => group._id)

        //console.log(data)
        if(avatarUrl !== "") data.profilePicture = avatarUrl
        if(props?.mode === 'edit'){
          return userService.update(data._id,data)
          .then(() => {
            setMessage("Se ha creado el usuario con éxito")
            setType('success')
            setTimeout(() => {
              setOpenToast(false)
            }, 5000);
            setOpenToast(true)
          })
          .catch(() => console.log("error"));
        }else{
          return userService.create(data)
          .then((data) => {
            setUserData(data?.data)
            setType('success')
            setMessage("Se ha creado el usuario con éxito")
            setOpenToast(true)
            setMode(true)
            navigate(`/users/edit/${data?.data._id}`,  { mode: 'edit' })
            setTimeout(() => {
              setOpenToast(false)
            }, 5000);
          })
          .catch((e) => console.log("error", e));
        }
        
    })

    const handleSubmitPosition = (event) => {
        event.preventDefault();
       
        let name = {
          name: dialogValue.name
        }
        userService.addPosition(name)
        .then((response) => {
            if(response){
              setPosition(position => position.concat([response.data]));
              setNewValuePosition(response.data)
              handleClose();
            }
        })
        .catch(() => console.log("error!"));
    
    }
    const handleSubmitGroup = (event) => {
        event.preventDefault();
        let name = {
          name: dialogGroupValue.name
        }
        userService.addGroup(name)
        .then((response) => {
            if(response){
              setGroupValue(group => group.concat([response.data]));
              setUsersGroups(group => group.concat([response.data]));
             // handleClose();
            }
        })
        handleCloseGroup();
    }


    const handleDelete = (data) => {
      setGroupValue(group => group.filter(item => item._id !== data._id));
    }

    useEffect(() => {
      const fetchPostion = async () =>  userService.getAllPositions()
      .then((response) => {
          if(response){
            setPosition(response.data);
          }
      })
      .catch(() => console.log("error!"));

      const fetchCompanies = async () =>  userService.getAllCompanies()
      .then((response) => {
          if(response){
            setCompanies(response.data);
          }
      })
      .catch(() => console.log("error!"));

      const fetchUserGroups = async () =>  userGroupsService.getAll()
      .then((response) => {
          if(response){
            setUsersGroups(response.data);
          }
      })
      .catch(() => console.log("error!"));

      fetchUserGroups();
      fetchCompanies();
      fetchPostion();

      
    }, []);

    useEffect(() => {
      if(props?.mode === 'edit'){
        userService.getById(id)
        .then((response) => {
            if(response){
              //console.log(response)
              reset(response?.data );
              setUserData(response.data)
            }
        })
      }
    },[])

    useEffect(() => {
      if(mode && id){
        return userService.getById(id)
        .then((response) => {
            if(response){
              reset(response?.data );
              setUserData(response.data)
            }
        })
      }
    }, [id, mode])

    const [tab, setTab] = useState("1");

    const handleChange = (event, newValue) => {
      setTab(newValue);
    };

    const handleOnChange = (event) => {
      const newImage = event.target?.files?.[0];
      if (newImage) {
        let reader = new FileReader();
        reader.onload = function(){
          setAvatarUrl(reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    };

    const uploadPicture = (event) => {
      inputFileRef.current.click();
    };
    
    const onChangeHandle = (newValue) => {
      setNewValue(newValue);
    };


    const onChangePositionHandle = (newValue) => {
      setNewValuePosition(newValue);
    };

    const openDialog = () => {
      toggleOpenGroup(true);
      setDialogGroupValue({
        name: newValue,
      });
    };

    const openPositionDialog = () => {
      toggleOpen(true);
      if(newValuePosition){
        if(typeof newValuePosition === 'string'){
          setDialogValue({
            name: newValuePosition,
          });
        }else{
          setDialogValue({
            name: "",
          });
        }
        
      }
      
    };

    const onDataChange = (data) => {
      let filtered = data.filter(user => user.selected).map(usr => usr._id)
      setPlacesIDs(filtered);
      setValue('users', filtered);
    }


    // useEffect(() => {
    //   console.log(permissions)
    //   if(permissions){
    //     setPermissions(permissions)
    //   }
    // }, [permissions]);

    return (
      <PageLayout>
        <Toaster open={openToast} type={type} message={message}></Toaster>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
          <div>
            <h2>Nuevo usuario</h2>
          </div>
          <form className="form-create-user" onSubmit={onSubmit}>
            
            <TabContext value={tab}>
              <Box sx={{ borderBottom: 1, borderColor: '#00ffff' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
                  <Tab className="space-right" icon={<Icon  size="s" icon="mt-answer-correct" color="black"></Icon>}  label="Datos del usuario" value="1" />
                  <Tab className="space-right" icon={<Icon  size="s" icon="mt-marker-map"  color="black"></Icon>} label="Lugares" value="2" />
                  <Tab className="space-right" icon={<Icon  size="s" icon="mt-maint-key"  color="black"></Icon>} label="Permisos" value="3" />
                </TabList>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                <Box className="box-profile" sx={{ minHeight: '375px', width: '350px', padding: '10px', marginRight: '10px', marginLeft: '-10px', display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <Box className="profilepic" sx={{ display:'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', margin: '0 auto', minHeight:'130px' }}>
                    {
                      avatarUrl
                      ?
                      <img src={avatarUrl} alt="Logo" className="user-empty profilepic__image" /> 
                      :
                      <img src={emptyUser} alt="Logo" className="user-empty profilepic__image" />
                    }
                      <div onClick={uploadPicture} className="profilepic__content">
                      <input
                          ref={inputFileRef}
                          accept="image/*"
                          hidden
                          id="avatar-image-upload"
                          type="file"
                          onChange={handleOnChange}
                        />
                        <span className="profilepic__icon"><CameraAlt></CameraAlt></span>
                      </div>
                    </Box>
                    <Box sx={{ display:'flex', justifyContent: 'center', alignItems: 'flex-start', marginBottom: '20px', flexDirection:'column', marginTop: '20px', }}>
                         <Typography
                            variant="h1"
                            noWrap
                            component="h1"
                            sx={{  marginBottom: '4px' }}
                          >
                            {userData.names} {userData.surnames}
                          </Typography>
                          <Typography
                            variant="subtitle"
                            noWrap
                            component="span"
                            sx={{fontSize: '12px'}}
                          >
                            {userData.email}
                          </Typography>
                      </Box>

                    <Box sx={{ display: 'flex', width: '100%', marginBottom: '20px', opacity: '0.2' }}>
                      <div className="horizontal-line"></div>
                    </Box>

                    <Box sx={{ display: 'flex', width: '100%', marginBottom: '20px' }}>
                      <FormSelect
                        options={companies}
                        name="company"
                        label="Empresa"
                        control={control}
                      >
                      </FormSelect>
                    </Box>

                    <FormSelect
                      options={role}
                      name="role"
                      label="Rol"
                      control={control}
                    >
                    </FormSelect>

                    <Box mb={2} sx={{ display: 'flex', width: '100%' }}>
                      <Dialog open={open} onClose={handleClose}>
                          <form>
                            <DialogTitle>Agregar nuevo cargo</DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                No encuentras el cargo? Por favor, agrégalo!
                              </DialogContentText>
                              <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                value={dialogValue.name}
                                onChange={(event) =>
                                  setDialogValue({
                                    ...dialogValue,
                                    name: event.target.value,
                                  })
                                }
                                label="title"
                                type="text"
                                variant="standard"
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClose}>Cancelar</Button>
                              <Button onClick={handleSubmitPosition} type="button">Agregar</Button>
                            </DialogActions>
                          </form>
                      </Dialog>
                    </Box>
                  <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                      <Box sx={{ width: '100%', marginRight: '10px' }}>
                        <Controller
                          name="position"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState }) => (
                            <Autocomplete
                              value={newValuePosition}
                              options={position}
                              onChange={(event, item) => {
                                onChange(item?._id);
                                setNewValuePosition(item)

                              }}
                              renderTags={() => null}
                              disableClearable
                              disableCloseOnSelect
                              sx={{ width: '100%' }}
                              getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                  return option;
                                }
                                if (option.inputValue) {
                                  console.log(option)
                                  return option.inputValue;
                                }
                                return option.name;
                              }}
                              renderOption={(props, option) => <li {...props}>{option.name}</li>}
                              renderInput={(params) => (
                                <TextField {...params}  
                                onChange={(event) => {
                                  if (event.target.value !== '' || event.target.value !== null) {
                                      onChangePositionHandle(event.target.value)
                                  }
                                }}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message} 
                                className="inputRounded selectRounded"
                                placeholder="Position" />
                              )}
                            />
                            )}/>
                      </Box>
                      <Box>
                        <Icon onClick={() => openPositionDialog()} icon="mt-plus" background="gray" color="black"></Icon>
                      </Box>
                    </Box>
                </Box>

                <Box sx={{ height: 'auto', width: '100%', padding: '10px', marginRight: '10px', marginLeft: '-10px' }}>
                  <TabPanel value="1"  sx={{ padding: '0'}}>
                      <Box sx={{ display: 'flex', width: '100%' }}>   
                        <Box mb={2} sx={{ display: 'flex', flexDirection: 'column', width: '100%', paddingTop: '20px' }}>
                          <Box mb={2} sx={{ display: 'flex', width: '100%',  flexDirection: 'row', marginRight: '10px'}}>
                            <FormInputText
                              name="names"
                              control={control}
                              label="Nombres"
                            >
                            </FormInputText>

                            <FormInputText
                              name="surnames"
                              control={control}
                              label="Apellidos"
                            >
                            </FormInputText>
                            
                          </Box>
                          <Box mb={2} sx={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
                            <FormInputText
                                name="email"
                                control={control}
                                label="Correo"
                                type="email"
                              >
                            </FormInputText>
                          
                            <FormInputText
                              name="telephone"
                              control={control}
                              label="Telefono"
                            >
                            </FormInputText>
                          </Box>

                          <Box mb={2} sx={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
                            <FormSelect
                              options={documentType}
                              name="documentType"
                              label="Tipo de documento"
                              control={control}
                            >
                            </FormSelect>
                            
                            <FormInputText
                              name="documentNumber"
                              control={control}
                              label="Numero de documento"
                              type="text"
                              >
                            </FormInputText>
                          </Box>
                          <Box mb={2} sx={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
                            <FormSelect
                                options={trackingSlot}
                                name="tracking"
                                label="Localización"
                                control={control}
                            >
                            </FormSelect>
                              
                            <Box mb={2} sx={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
                              <Box sx={{ width: '100%', marginRight: '10px' }}>
                                <Controller
                                  name="groupvalue"
                                  control={control}
                                  defaultValue={[]}
                                  render={({ field: { onChange, value }, fieldState }) => (
                                    <Autocomplete
                                      multiple
                                      value={groupvalue}
                                      options={usersgroups}
                                      onChange={(event, value) => {
                                        setGroupValue(value);
                                        onChange(value)

                                      }}
                                      renderTags={() => null}
                                      disableClearable
                                      disableCloseOnSelect
                                      getOptionLabel={(option) => option.name}
                                      renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                          <Checkbox
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option.name}
                                        </li>
                                      )}
                                      renderInput={(params) => (
                                        <TextField {...params}  
                                        onChange={(event) => {
                                          if (event.target.value !== '' || event.target.value !== null) {
                                              onChangeHandle(event.target.value)
                                          }
                                        }}
                                          
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message} 
                                        className="inputRounded selectRounded"
                                        placeholder="Grupo de usuarios" />
                                      )}
                                    />
                                    )}
                                    rules={{ required: 'grupo de usuarios required' }}/>

                                <Box sx={{ display: 'flex', width: '100%', marginTop: '10px'}}>
                                  {groupvalue.map((option) => (
                                    <Chip 
                                      label={option.name}
                                      onDelete={() => handleDelete(option)}
                                    />
                                  ))}
                                </Box>
                              </Box>
                              <Box>
                                <Icon onClick={() => openDialog()} icon="mt-plus" background="gray" color="black"></Icon>
                              </Box>
                              <Dialog open={openGroup} onClose={handleCloseGroup}>
                                      <form>
                                        <DialogTitle>Agregar nuevo grupo de usuario</DialogTitle>
                                        <DialogContent>
                                          <DialogContentText>
                                            No encuentras el grupo? Por favor, agrégalo!
                                          </DialogContentText>
                                          <TextField
                                            autoFocus
                                            margin="dense"
                                            id="name"
                                            value={dialogGroupValue.name}
                                            onChange={(event) =>
                                              setDialogGroupValue({
                                                ...dialogValue,
                                                name: event.target.value,
                                              })
                                            }
                                            label="title"
                                            type="text"
                                            variant="standard"
                                          />
                                        </DialogContent>
                                        <DialogActions>
                                          <Button onClick={handleCloseGroup}>Cancelar</Button>
                                          <Button onClick={handleSubmitGroup} type="button">Agregar</Button>
                                        </DialogActions>
                                      </form>
                              </Dialog>
                            </Box>
                          </Box>
                        </Box>
                      
                      </Box>
                  </TabPanel>
                  <TabPanel value="2">
                    <Box sx={{ width: '100%', marginBottom: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                      <SearchFilterLayout></SearchFilterLayout>
                    </Box>
                      <Places actions={false} idGroup={id} handleChangeCheck={onDataChange}></Places>
                  </TabPanel>
                  <TabPanel value="3">
                  <Box sx={{ width: '50%', marginBottom: '20px', display: 'flex', justifyContent: 'flex-start' }}>
                      <Typography
                        variant="h1"
                        noWrap
                        component="h1"
                      >
                        Módulos
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%', marginBottom: '20px', display: 'flex', justifyContent: 'flex-start' }}>

                      <Box mb={2} sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                         
                      <Controller
                          name="modules.activities"
                          control={control}
                          defaultValue={modules.activities}
                          render={({ field: { onChange, value }, fieldState: { error } }) => <Checkbox 
                          defaultChecked={modules.activities} 
                          onChange={(event, value) => {
                            onChange(value)
                          }}
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, paddingLeft: 0 }} />}
                        />
                          <p className="subtitle-heading">Actividades</p>
                      </Box>

                      <Box mb={2} sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                      <Controller
                          name="modules.attendance"
                          control={control}
                          defaultValue={modules.attendance}
                          render={({ field: { onChange, value }, fieldState: { error } }) => <Checkbox 
                            defaultChecked={modules.attendance}
                            onChange={(event, value) => {
                              onChange(value)
                            }}
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, paddingLeft: 0 }} />}
                        />
                          <p className="subtitle-heading">Asistencia</p>
                      </Box>
                      
                    </Box>
                    <div className="invalid-feedback">{errors.modules?.attendance?.message || errors.modules?.activities?.message}</div>
                  </TabPanel>
                </Box>
              </Box>
              <Box mb={2} sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', }}>
                <Box mb={2} sx={{ marginRight: '10px' }}>
                  <ButtonSubmit 
                          onClick={() => navigate('/users')} 
                          title="Atrás"
                          type="secondary" 
                        >
                  </ButtonSubmit>
                </Box>
                <Box mb={2} sx={{ marginRight: '10px' }}>
                  {props.mode === 'edit'
                    ? (
                      <ButtonSubmit 
                        onClick={onSubmit} 
                        title="Actualizar" 
                        >
                      </ButtonSubmit>
                    )
                    : (
                      <ButtonSubmit 
                          onClick={onSubmit} 
                          title="Guardar" 
                        >
                      </ButtonSubmit>
                    )   
                  }
                  
                </Box>
              </Box>
            </TabContext>
          </form>
         
        
        </Box>
      </PageLayout>
    );
}

const documentType = [
  {
    value: 'dni',
    name: 'DNI',
  },
  {
    value: 'ce',
    name: 'CE',
  },
  {
    value: 'passport',
    name: 'PASAPORTE',
  },
  {
    value: 'ruc',
    name: 'RUC',
  },
];

const role = [
{
  value: 'admin',
  name: 'Administrador',
},
{
  value: 'superviser',
  name: 'Supervisor',
},
{
  value: 'user',
  name: 'Usuario',
},
{
  value: 'viewer',
  name: 'Visor',
},
];

const trackingSlot = [
  {
    value: 'no_tracking',
    name: 'Sin tracking',
  },
  {
    value: '15',
    name: '15 minutos',
  },
  {
    value: '30',
    name: '30 minutos',
  },
  {
    value: '45',
    name: '45 minutos',
  },
  {
    value: '1 hour',
    name: '1 hora',
  },
  {
    value: '2 hours',
    name: '2 horas',
  },
];



export default Create;

import { useEffect } from 'react';
import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";
////////////////////////////////////////////////////////////////////////////////////////////////////

import { useAuthDispatch } from '../../context/authContext'

////////////////////////////////////////////////////////////////////////////////////////////////////
import Layout from "../Layout/index";
import Create from "../../../Users/pages/Create/create";
import CreatePlace from "../../../Places/pages/Create/index";
import User from "../../../Users/pages/Overview/index";
import List from "../../../Users/index";
import UserGroup from '../../../UsersGroups/index';
import PlacesGroup from '../../../PlacesGroups/index';
import UserGroupCreate from '../../../UsersGroups/pages/Create/create';
import PlacesGroupCreate from '../../../PlacesGroups/pages/Create/index';
import PlacesList from "../../../Places/pages/Overview/index";
import PlacesLayout from "../../../Places/index";
import NotAllowed from "../NotAllowed/index";

function AuthenticatedApp() {
  const authDispatch = useAuthDispatch();
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if(token) {
      authDispatch.setProfile(token);
    }
  }, []);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Navigate to ="/activities" />}/>
        <Route path="/users" element={<List />}>
          <Route path="" element={<User />}/>
          <Route path="groups" element={<UserGroup />}/>
        </Route>
        <Route path="/users/groups/create" element={<UserGroupCreate />} />
        <Route path="/users/groups/edit/:id" element={<UserGroupCreate mode='edit' />} />
        
        <Route path="/users/create/" element={<Create />} />
        <Route path="/users/edit/:id" element={<Create mode="edit" />}/>

        <Route path="/places" element={<PlacesLayout />}>
          <Route path="" element={<PlacesList />}/>
          <Route path="groups" element={<PlacesGroup />}/>
        </Route>
        <Route path="/places/groups/create" element={<PlacesGroupCreate />} />
        <Route path="/places/groups/edit/:id" element={<PlacesGroupCreate mode='edit' />} />
 
        <Route path="/places/create/" element={<CreatePlace />}/>
        <Route path="/places/edit/:id" element={<CreatePlace mode="edit" />}/>

        <Route path="/not-allowed"  element={<NotAllowed />}/>

      </Routes>
    </Layout>
  );
}

export default AuthenticatedApp;
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { placesService } from "../../services/places.service";
import Icon from "../../../../components/atoms/Icon";
import PropTypes from 'prop-types';
import DialogConfirm from "../../../../components/molecules/DialogConfirm/index";
import Chip from "../../../../components/atoms/Chip/index"; 
import CustomizedTooltip from "../../../../components/atoms/Tooltip/index"
////////////////////////////////////////////////////////////////////////////////////////////////////
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses }  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import { placesGroupsService } from '../../../PlacesGroups/services/placegroup.service';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#dddddd',
    color: '#000',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function Places(props) {
  const [allChecked, setAllChecked] = useState(false);
  const navigate = useNavigate();
  const [APIData, setAPIData] = useState([]);
  const [open, setOpen] = useState(false);
  const [placeEdit, setPlaceEdit] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  useEffect(() => {
    //console.log(props)

    const fetchUserChecks = async () =>{
      if(props?.idGroup){
        let places
        await placesGroupsService.getById(props.idGroup)
          .then((response) => {
            //console.log("places",response)
            places = response?.data?.places
            if(places?.length > 0){
              return setAPIData(APIData => APIData.map(data => {
                return  places.some(places  => places._id === data._id) 
                ? { ...data, selected: true } 
                : data
              }));
            }
          })
          .catch((e) => console.log("error", e));
      }
    }

    const fetchPlaces = async () => {
      placesService.getAll()
        .then((response) => {
          let placesDB = response.data
          //console.log("props",props)
          setAPIData(placesDB);
          if(props?.idGroup){
            fetchUserChecks()
          }
        })
        .catch((e) => console.log("error!", e));
    }
    fetchPlaces()
  }, [])

   
  useEffect(() => {
    if(props && props.handleChangeCheck){
      props?.handleChangeCheck(APIData)
    }else{
      return;
    }
  }, [APIData])

  useEffect(() => {
    checkIfAllselected()
  })

  const onEdit = (id) => {
    navigate('/places/edit/'+ id)
  }

  const checkSelected =  (data) => {
    if(data?.selected === true){
      return true
    }else{
      return false
    }
  }

  const handleChange = (event) => {
    setAPIData(APIData => APIData.map(data => data._id === event._id ? { ...data, selected: !data.selected } : data));
  };


  const handleCheckAll = () => {
    setAPIData(APIData => APIData.map(data => ({ ...data, selected : !allChecked }) ));
    setAllChecked(!allChecked);
    props.handleChangeCheck(APIData)
  }

  const someCompleted = () =>{
    return APIData.filter(usr => usr.selected).length > 0 && !allChecked;
  }

  const checkIfAllselected = () => {
    if(APIData.filter(usr => usr.selected).length === APIData.length){
      setAllChecked(true)
    }else{
      setAllChecked(false)
    }
  }


  const onOpenConfirmation = (user) => {
    console.log(user)
    setPlaceEdit(user)
    setOpen(true);
  }

  const onStatusUpdate = () => {
    let newStatus = {};
    if(placeEdit.isBlocked){
      newStatus.isBlocked = false;
    }else{
      newStatus.isBlocked = true
    }
    placesService.blockPlace(placeEdit._id,newStatus)
    .then((response) => {
      setAPIData(APIData => APIData.map(data => data._id === response.data._id ? { ...response.data} : data));
      setOpen(false);
      setOpenSuccess(true)
    })
  }

  const handleClose = () => {
    setOpen(false);
  };


  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };


  return (
    <div>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
            <TableContainer className="table-custom" component={Paper}>
            <Table sx={{ minWidth: 300 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                {props.actions === false  && 
                    <StyledTableCell>
                      <Box mb={2} sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: '0' }}>
                        <Checkbox
                          checked={allChecked}
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, paddingLeft: 0 }}
                          indeterminate={someCompleted()}
                          onChange={handleCheckAll}
                        />
                      </Box>
                    </StyledTableCell>
                  }
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>{props.actions === true ? 'Nombre' : 'Lugar' }</StyledTableCell>
                  {props.actions === true && 
                    <StyledTableCell>Detalle</StyledTableCell>
                  }
                  <StyledTableCell>Departamento</StyledTableCell>
                  <StyledTableCell>Provincia</StyledTableCell>
                  <StyledTableCell>Distrito</StyledTableCell>
                  {props.actions === true && 
                    <StyledTableCell>Estado</StyledTableCell>
                  }
                  {props.actions === true && <StyledTableCell>Acciones</StyledTableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {APIData.map((data)  => (
                  <StyledTableRow
                    key={data._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {props.actions === false &&
                      <StyledTableCell component="th" scope="data">
                        <Box mb={2} sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: '0' }}>
                          <FormControlLabel
                              label=""
                              control={
                              <Checkbox 
                              sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, paddingLeft: 0 }}
                              checked={checkSelected(data)}
                              onChange={() => handleChange(data)}
                              />}
                            />
                        </Box>
                      </StyledTableCell>
                    }
                    <StyledTableCell component="th" scope="data">
                      {data.id}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="data">
                    {props.actions === true ? data.name : data.name + ' - ' + data.detail}
                      
                    </StyledTableCell>
                    {props.actions === true && <StyledTableCell component="th" scope="data">
                      {data.detail}
                    </StyledTableCell>
}
                    <StyledTableCell component="th" scope="data">
                      {data.department}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="data">
                      {data.province}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="data">
                      {data.district}
                    </StyledTableCell>
                    {props.actions === true && 
                      <StyledTableCell>
                        <Chip status={data?.isBlocked ? 'blocked' : 'active'}></Chip>
                      </StyledTableCell>
                    }
                    {props.actions === true && 
                    <StyledTableCell>
                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', }}>
                    <CustomizedTooltip label="Editar">
                      <IconButton onClick={() => onEdit(data._id)} aria-label="delete">
                        <Icon icon="mt-lapiz-box" color="black"></Icon>
                      </IconButton>
                    </CustomizedTooltip>

                    <CustomizedTooltip label={data?.isBlocked ? 'Habilitar' : 'Inhabilitar' }>
                      <IconButton onClick={() => onOpenConfirmation(data)} aria-label="delete">
                        {!data?.isBlocked 
                        ?  <Icon icon="mt-alert-two" color="pink"></Icon>
                        :
                          <Icon icon="mt-check" color="green"></Icon>
                        }
                      </IconButton>
                    </CustomizedTooltip>
                        
                    </Box>
                    </StyledTableCell>
                    }
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        
          <DialogConfirm
            title={placeEdit.isBlocked ? 'Habilitar lugar' : 'Inhabilitar lugar'}
           open={open}
           onClick={onStatusUpdate}
           onCancel={handleClose}>
             {
               placeEdit.isBlocked
               ?
               `El lugar (${placeEdit.name} - ${placeEdit.detail}) estará disponible en la plataforma.`
               :
               `El lugar (${placeEdit.name} - ${placeEdit.detail}) ya no estará disponible para los usuarios. Todos los registros en este lugar seguirán disponibles.`
             }
          </DialogConfirm>

          <DialogConfirm
            title={placeEdit.isBlocked ? 'Habilitar lugar' : 'Inhabilitar lugar'}
           open={openSuccess}
           type="success"
           onClick={handleCloseSuccess}>
             {
               placeEdit.isBlocked
               ?
               `${placeEdit.name} - ${placeEdit.detail} habilitado.`
               :
               `${placeEdit.name}  - ${placeEdit.detail} inhabilitado.`
             }
          </DialogConfirm>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop:'30px', alignItems:' center'}}>
          <Box>
            <Typography
              variant="p"
              noWrap
              component="div"
              >
              Mostrando registros del 1 al 19 un total de 70 registros
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
            <Pagination count={10} variant="outlined" shape="rounded" />
          </Box>
        </Box>
        
    </div>
  );
}

Places.propTypes = {
  actions:PropTypes.bool,
};


Places.defaultProps = {
  actions: true,
};
import { Controller } from "react-hook-form";
import { useState } from "react";
import { userService } from "../../../features/Users/services/user.service";
import { makeStyles } from '@mui/styles';

////////////////////////////////////////////////////////////////////////////////////////////////////
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const FormInputText = ({ name, control, label, type }) => {

  const [validEmail, setValidEmail] = useState(false);

  const handleBlur = (e) => {
    let obj = {
      email: e.target.value
    }
    fetchPostion(obj)
  }


  const fetchPostion = async (value) =>  {
    userService.checkDuplicateEmail(value)
      .then((response) => {
          if(response){
            setValidEmail(false)
          }
    })
    .catch(() => {
      setValidEmail(true)
    });
  }

  const useStyles = makeStyles(() => ({
    noBorder: {
      border: "none",
    },
    root: {
    "& .MuiFilledInput-root": {
      background: "#000"
    }
  }
  }));
  const classes = useStyles();

  return (
    
    <Box mb={2} sx={{ display: 'flex', width: '100%' }}>
      {type === 'email' && 
            <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
            return(
            <TextField
                id="outlined-select-currency"
                className="inputRounded"
                sx={{ marginRight: '10px' }}
                placeholder= {label}
                value={value ?? ""}
                error={!!error || validEmail}
                type={type}
                classes={{notchedOutline:classes.input}}
                InputProps={{
                  classes:{notchedOutline:classes.noBorder}
                }}
                helperText={(error) ? error?.message  : null}
                onChange={onChange}>
            </TextField>)
            }}
            
        /> }

        {type !== 'email' &&
          <Controller
              name={name}
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
              return(
              <TextField
                  id="outlined-select-currency"
                  className="inputRounded"
                  sx={{ marginRight: '10px' }}
                  placeholder= {label}
                  value={value ?? ""}
                  error={!!error}
                  type={type}
                  classes={{notchedOutline:classes.input}}
                  helperText={(error) ? error?.message : null}
                  onChange={onChange}
                  InputProps={{
                    classes:{notchedOutline:classes.noBorder}
                  }}>
              </TextField>)
              }}
              
          />
        }
    </Box>
  );
};

  
FormInputText.defaultProps = {
    type: 'text',
};


export default FormInputText;
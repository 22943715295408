import { Controller } from "react-hook-form";
////////////////////////////////////////////////////////////////////////////////////////////////////
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const TextArea = ({ name, control, label, type, rows }) => {

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
            return(
            <TextField
                sx={{ marginRight: '10px' }}
                placeholder= {label}
                value={value}
                multiline
                rows={rows || 3}
                error={!!error}
                type={type}
                fullWidth
                helperText={(error) ? error?.message : null}
                onChange={onChange}>
            </TextField>)
            }}
            
        /> 
       
    </Box>
  );
};

  
TextArea.defaultProps = {
    type: 'text',
};


export default TextArea;
export const authInitialState = {
    error: '',
    isError: false,
    isAuthenticated: false,
    isAuthenticating: false,
    user: null,
    authToken: null,
  };
  
  export const authReducer = (state, action) => {
    switch (action.type) {
      case 'LOGIN.REQUEST': {
        return { ...state, isAuthenticating: true, isError: false, error: false };
      }
      case 'LOGIN.SUCCESS': {
        return { ...state, isAuthenticating: false, isAuthenticated: true, authToken: action.payload };
      }
      case 'LOGIN.FAIL': {
        return { ...state, isAuthenticating: false, isError: true, error: action.payload };
      }
      case 'LOGOUT.SUCCESS': {
        return { ...state, isAuthenticated: false, user: null, authToken: null };
      }
      case 'LOGOUT.FAIL': {
        return { ...state, error: action.payload };
      }
      case 'SET.PROFILE': {
        return { ...state, isAuthenticating: false, isAuthenticated: true, user: action.payload };
      }
      default: {
        throw new Error(`${action.type}`);
      }
    }
  };
  
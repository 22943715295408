import PropTypes from 'prop-types';

////////////////////////////////////////////////////////////////////////////////////////////////////
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from "../../atoms/Button/index";
////////////////////////////////////////////////////////////////////////////////////////////////////


const getTitle = (props) => {
    if(props.type === 'confirm') {
        return 'Aceptar'
    }else{
        return 'Entendido'
    }
}
const DialogConfirm = (props) => {
    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={props.open}
        >
        <div className="modal-header">
            <DialogTitle>{props.title}</DialogTitle>
        </div>
            <DialogContent dividers>
                {props.children}
            </DialogContent>
        <DialogActions>
            {props.type === 'confirm' && 
                <Button title="Cancelar" type="secondary" autoFocus onClick={props.onCancel}>
                </Button>
            }
          
          <Button title={getTitle(props)} onClick={props.onClick}></Button>
        </DialogActions>
      </Dialog>
    )

}


DialogConfirm.propTypes = {
    title:PropTypes.string,
    open:PropTypes.bool,
    type:PropTypes.string,
    onClick: PropTypes.func,
    onCancel: PropTypes.func,
};

DialogConfirm.defaultProps = {
    type: 'confirm',
}
export default DialogConfirm;
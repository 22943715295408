import React from 'react';
////////////////////////////////////////////////////////////////////////////////////////////////////

const CircleIcon = (props) => {

   let pathImg = require(`../../../assets/images/login/${props.srcImg}`).default

    return (
        <div>
            <div
             className={"circle " + (props.type === 'big' ? 'circle-big' : 'circle-small')}>
                <img src={pathImg} alt="Logo" />
            </div>
            <p className="title">{props.title}</p>
        </div>

    );
}

export default CircleIcon;
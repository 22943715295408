import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Icon from "../Icon/index";
import { makeStyles } from '@mui/styles';
////////////////////////////////////////////////////////////////////////////////////////////////////



const ButtonSearch = (props) => {

  const useStyles = makeStyles(() => ({
    noBorder: {
      border: "none",
    },
    root: {
    "& .MuiFilledInput-root": {
      background: "#000"
    }
  }
  }));
  const classes = useStyles();
  
   return ( 
   <Box sx={{ width: '100%' }}>
      <TextField
        placeholder="Búsqueda"
        className="inputRoundedSearch"
        variant="outlined"
        style={{ borderRadius: 25 }}
        fullWidth
        classes={{notchedOutline:classes.input}}
        InputProps={{
          classes:{notchedOutline:classes.noBorder}
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
                <Icon icon="mt-search" size="s" color="black"></Icon>
            </InputAdornment>
          ),
        }}
      />
    </Box>
   )
}

export default ButtonSearch;
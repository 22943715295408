import { React, useEffect, useState } from 'react';
import ButtonSearch from '../../atoms/ButtonSearch';
import Icon from '../../atoms/Icon';
import { useNavigate, useLocation } from 'react-router-dom';
import ButtonTab from '../../../components/atoms/ButtonTab';
import SelectShowItems from '../../../components/molecules/SelectShowItems/index';
////////////////////////////////////////////////////////////////////////////////////////////////////

const ActionsLayout = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState(false);

  useEffect(() => {
    const checkRoute = () => {
      switch (location.pathname) {
        case '/users':
          return { isUser: true, actions: true, isPlace: false };
        case '/places':
          return { isUser: false, actions: true, isPlace: true };

        case '/users/groups':
          return { isUser: true, actions: false, isPlace: false };

        case '/users/groups/create':
          return { isUser: true, actions: false, isPlace: false };

        case '/places/groups':
          return { isUser: false, actions: false, isPlace: false };

        case '/places/groups/create':
          return { isUser: false, actions: false, isPlace: false };

        default:
          return { isUser: true, actions: true };
      }
    };

    let data = checkRoute();
    if (data) {
      setUser(data);
    }
  }, [location]);

  return (
    <div className="container-layout">
      <div className="row">
        <div className="actions-tab">
          {user.isUser ? (
            <ButtonTab
              itemLeft={{ name: 'Usuarios', url: '/users' }}
              active={
                location.pathname === '/users' &&
                location.pathname !== '/users/groups'
                  ? 'item-1'
                  : 'item-2'
              }
              itemRight={{ name: 'Grupos', url: '/users/groups' }}
            ></ButtonTab>
          ) : (
            <ButtonTab
              itemLeft={{ name: 'Lugares', url: '/places' }}
              active={
                location.pathname === '/places' &&
                location.pathname !== '/places/groups'
                  ? 'item-1'
                  : 'item-2'
              }
              itemRight={{ name: 'Grupos', url: '/places/groups' }}
            ></ButtonTab>
          )}
        </div>

        <div className="right-side">
          <div className="search-show">
            <SelectShowItems
              pageSize="10"
              pageSizes={[10, 50, 100]}
            ></SelectShowItems>
          </div>
          <div className="search-buttons">
            <ButtonSearch></ButtonSearch>
          </div>
          {user.actions ? (
            <div className="actions-buttons">
              {(user.isUser || user.isPlace) && (
                <Icon
                  tooltip="Mostrar habilitados"
                  icon="mt-check"
                  background="green"
                  color="white"
                ></Icon>
              )}
              {user.isUser && (
                <Icon
                  tooltip="Mostrar pendientes"
                  icon="mt-alert"
                  background="yellow"
                  color="white"
                ></Icon>
              )}
              {(user.isUser || user.isPlace) && (
                <Icon
                  tooltip="Mostrar inhabilitados"
                  icon="mt-stop"
                  background="red"
                  color="white"
                ></Icon>
              )}
              <Icon
                tooltip="Filtrar"
                icon="mt-filter-two"
                background="gray"
                color="black"
              ></Icon>
              <Icon
                tooltip="Descargar"
                icon="mt-download"
                background="gray"
                color="black"
              ></Icon>
              <Icon
                tooltip="Imprimir"
                icon="mt-super-answers"
                background="gray"
                color="black"
              ></Icon>
              <Icon
                tooltip="Agregar nuevo"
                onClick={() => navigate('create')}
                icon="mt-plus"
                background="gray"
                color="black"
              ></Icon>
            </div>
          ) : (
            <div className="actions-buttons">
              <Icon
                tooltip="Filtrar"
                icon="mt-filter-two"
                background="gray"
                color="black"
              ></Icon>
              <Icon
                tooltip="Crear grupo"
                onClick={() => navigate('groups/create')}
                icon="mt-plus"
                background="gray"
                color="black"
              ></Icon>
            </div>
          )}
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default ActionsLayout;

import React from 'react';
import PropTypes from "prop-types";
import Icon from "../../../components/atoms/Icon/index";
////////////////////////////////////////////////////////////////////////////////////////////////////

const getIcon = (props) => {
    switch (props.status) {
        case 'active':
            return 'mt-check';
        case 'enabled':
            return 'mt-check';
        case 'pending':
            return 'exclamation-circle';
        case 'blocked':
            return 'mt-alert-two';
        default:
            return 'mt-alert-two';
    }
}

const getLabel = (props) => {
    switch (props.status) {
        case 'active':
            return 'Habilitado';
        case 'enabled':
            return 'Habilitado';
        case 'pending':
            return 'Invitado';
        case 'blocked':
            return 'Inhabilitado';
        default:
            return 'Inhabilitado';
    }
}

const getBackground = (props) => {
    switch (props.status) {
        case 'active':
            return `chip-container background-green`;
        case 'enabled':
            return `chip-container background-green`;
        case 'pending':
            return 'chip-container background-yellow';
        case 'blocked':
            return 'chip-container background-red';
        default:
            return 'chip-container background-red';
    }
}
const Chip = (props) => {
    return (
        <div className={getBackground(props)}>
            <div className="icon-text">
                <Icon icon={getIcon(props)} color="white"></Icon>
            </div>
            <div className="vertical-line-chip"></div>
            <span>{getLabel(props)}</span>
        </div>

    );
}

Chip.propTypes = {
    icon: PropTypes.string.isRequired,
    background: PropTypes.string,
};
  
Chip.defaultProps = {
    background: 'gray',
    icon: 'mt-alert',
};


export default Chip;
import React, { useReducer, createContext, useContext, useCallback, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { authReducer, authInitialState } from '../reducers/authReducer';
import { isJwtExpired } from 'jwt-check-expiration';
import clienteAxios from '../../../config/axios';

export const UserAuthState = createContext({});

export const UserAuthDispatch = createContext({});

export const UserAuthProvider = ({ children }) => {

    const [state, dispatch] = useReducer(authReducer, authInitialState);
  
    const login = useCallback(async user => {
      try {
        dispatch({ type: 'LOGIN.REQUEST' });
        const { data } = await clienteAxios.post('/auth', user);
        if (data.token) {
            saveOnLocal(data.token);
            dispatch({ type: 'LOGIN.SUCCESS', payload: data.token });
        } else {
          throw new Error(data);
        }
      } catch (error) {
        console.log(error?.message)
        if(error?.message === 'Your account is blocked'){
          console.log('Your account is blocked')
          dispatch({ type: 'LOGIN.FAIL', payload: 'account blocked' });
        }else{
          dispatch({ type: 'LOGIN.FAIL', payload: 'invalid email or password' });
        }
      }
    }, []);
  
    const logout = useCallback(async () => {
      try {
        dispatch({ type: 'LOGOUT.SUCCESS' });
        removeLocalStorage();
      } catch (e) {
        dispatch({ type: 'LOGOUT.FAIL', payload: e.message });
      }
    }, []);

    const setProfile = useCallback(async token => {
      try {
          if (isJwtExpired(token)) {
            console.log("expired")
            this.logout()
          } else {
            const user = jwt_decode(token);
            dispatch({ type: 'LOGIN.SUCCESS', payload: token });
            dispatch({ type: 'SET.PROFILE', payload: user });
          }
      } catch (error) {
        dispatch({ type: 'LOGOUT.FAIL', payload: 'something is wrong' });
      }
    }, []);

    useEffect(() => {
      if (localStorage.token) {
        const decoded = localStorage.token ? localStorage.token : '';
        if (decoded) {
          //console.log('set_profile')
          const user = jwt_decode(decoded);
          dispatch({ type: 'LOGIN.SUCCESS', payload: decoded });
          dispatch({ type: 'SET.PROFILE', payload: user });
        }
      } else {
        console.log('not-login')
      }
    }, [state.auth]);

    
    return (
      <UserAuthState.Provider value={state}>
        <UserAuthDispatch.Provider value={{ login, logout, setProfile }}>{children}</UserAuthDispatch.Provider>
      </UserAuthState.Provider>
    );
};

export const useAuthState = () => {
    const state = useContext(UserAuthState);
    //console.log(state)
    if (state === undefined) {
      throw new Error('useAuthState must be used within a UserAuthProvider');
    }
  
    return state;
  };
  
export const useAuthDispatch = () => {
    const dispatch = useContext(UserAuthDispatch);

    if (dispatch === undefined) {
        throw new Error('useAuthDispatch must be used within a UserAuthProvider');
    }

    return dispatch;
};
  

function saveOnLocal(token) {
    if (token) {
        localStorage.setItem('token', token);
    }
}

function removeLocalStorage() {
    localStorage.removeItem('token');
}
  
import clienteAxios from '../../../config/axios';

export const userGroupsService = {
    getAll,
    getById,
    create,
    update,
    delete: _delete
};


function getAll() {
    return clienteAxios.get(`/usersgroups`);
}

function getById(id) {
    return clienteAxios.get(`/usersgroups/${id}`);
}

function create(params) {
    return clienteAxios.post(`/usersgroups`, params)
    //return clienteAxios.post('/users/groups', params);
}

function update(id, params) {
    return clienteAxios.put(`/usersgroups/${id}`, params);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(id) {
    return clienteAxios.delete(`/usersgroups/${id}`);
}

import { useAuthState } from './features/Auth/context/authContext'
import  AuthenticatedApp from "./features/Auth/pages/AuthenticatedApp/index";
import  Login  from "./features/Auth/pages/Login/index.js";
////////////////////////////////////////////////////////////////////////////////////////////////////

import './App.scss';
import './styles/Tokens.scss';
import './styles/Styles.scss';

////////////////////////////////////////////////////////////////////////////////////////////////////


require('dotenv').config();

function App() {

  const  { isAuthenticated } = useAuthState();

  return isAuthenticated ? <AuthenticatedApp /> : <Login />;
}

export default App;

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { placesGroupsService } from '../../services/placegroup.service';
import ButtonSubmit from '../../../../components/atoms/Button/index';
import Places from '../../../Places/pages/Overview/index';
import { useState, useEffect } from 'react';
import SearchFilterLayout from '../../../../components/molecules/SearchFilterLayout/index';
import { useParams } from 'react-router-dom';
import FormInputText from '../../../../components/atoms/FormInput/index';
import TextArea from '../../../../components/atoms/TextArea/index';
import Toaster from '../../../../components/atoms/Toast';
////////////////////////////////////////////////////////////////////////////////////////////////////
import Box from '@mui/material/Box';
////////////////////////////////////////////////////////////////////////////////////////////////////

const schema = yup
  .object({
    name: yup.string().required('Este campo es requerido'),
  })
  .required();

const Create = (props) => {
  const [name, setName] = useState('');
  const [mode, setMode] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [users, setUsers] = useState('');
  let { id } = useParams();

  const navigate = useNavigate();
  const [usersIDs, setUsersIDs] = useState([]);

  const { control, reset, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((data) => {
    data.places = usersIDs;
    if (props?.mode === 'edit') {
      return placesGroupsService
        .update(data._id, data)
        .then((data) => {
          setOpenToast(true);
          //navigate('/places/groups')
        })
        .catch(() => console.log('error'));
    } else {
      return placesGroupsService
        .create(data)
        .then((data) => {
          // console.log(data.data)
          setOpenToast(true);
          setMode(true);
          navigate(`/places/groups/edit/${data?.data._id}`, { mode: 'edit' });
          //navigate('/places/groups')
          setTimeout(() => {
            setOpenToast(false);
          }, 5000);
        })
        .catch((e) => console.log('error', e));
    }
  });

  const onDataChange = (data) => {
    let filtered = data.filter((user) => user.selected).map((usr) => usr._id);
    setUsersIDs(filtered);
  };

  useEffect(() => {
    if (props?.mode === 'edit') {
      placesGroupsService
        .getById(id)
        .then((response) => {
          reset(response?.data);
          setUsers(response?.data?.places);
        })
        .catch((e) => console.log('error', e));
    }
  }, [name]);

  useEffect(() => {
    if (mode && id) {
      placesGroupsService
        .getById(id)
        .then((response) => {
          console.log(response.data);
          reset(response?.data);
          setUsers(response?.data?.places);
        })
        .catch((e) => console.log('error', e));
    }
  }, [id, mode]);

  return (
    <div className="container-layout">
      <Toaster
        open={openToast}
        message={
          mode || props.mode === 'edit'
            ? 'Se ha actualizado el grupo con éxito'
            : 'Se ha creado el grupo con éxito'
        }
      ></Toaster>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
        <div className="row">
          <div>
            {props.mode === 'edit' ? (
              <h2>Editar grupo lugar</h2>
            ) : (
              <h2>Nuevo grupo de lugar</h2>
            )}

            <form className="form-groups" onSubmit={onSubmit}>
              <Box sx={{ display: 'flex', width: '50%' }}>
                <FormInputText
                  name="name"
                  control={control}
                  label="Nombre de grupo"
                ></FormInputText>
              </Box>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <TextArea
                  name="description"
                  control={control}
                  label="Descripción"
                  rows={5}
                ></TextArea>
              </Box>
            </form>
          </div>
          <div className="right-side-search container-end">
            <SearchFilterLayout></SearchFilterLayout>
          </div>
        </div>
        <Places
          actions={false}
          idGroup={id}
          handleChangeCheck={onDataChange}
        ></Places>
        <Box
          mb={2}
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginTop: '30px',
          }}
        >
          <Box mb={2} sx={{ marginRight: '10px' }}>
            <ButtonSubmit
              onClick={() => navigate('/places/groups')}
              title="Atrás"
              type="secondary"
            ></ButtonSubmit>
          </Box>
          <Box mb={2} sx={{ marginRight: '10px' }}>
            {props.mode === 'edit' ? (
              <ButtonSubmit
                onClick={onSubmit}
                title="Actualizar"
              ></ButtonSubmit>
            ) : (
              <ButtonSubmit onClick={onSubmit} title="Guardar"></ButtonSubmit>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};
export default Create;
